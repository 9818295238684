import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import defaultColor from './defaultColor';

export default function ParentKitIcon(props) {
  const color = props.color === 'secondary' ? '#b1b1b1' : props.color;
  return <SvgIcon {...props} viewBox="0 0 14 14" fill="none">
    <path id="Vector" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 4.75c1.28 0 2 -0.72 2 -2s-0.72 -2 -2 -2 -2 0.72 -2 2 0.72 2 2 2Z" strokeWidth="1"></path>
    <path id="Vector_2" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M2.25 13.25c0.96 0 1.5 -0.54 1.5 -1.5s-0.54 -1.5 -1.5 -1.5 -1.5 0.54 -1.5 1.5 0.54 1.5 1.5 1.5Z"
          strokeWidth="1"></path>
    <path id="Vector_3" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M7 13.25c0.96 0 1.5 -0.54 1.5 -1.5s-0.54 -1.5 -1.5 -1.5 -1.5 0.54 -1.5 1.5 0.54 1.5 1.5 1.5Z"
          strokeWidth="1"></path>
    <path id="Vector_4" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M11.75 13.25c0.96 0 1.5 -0.54 1.5 -1.5s-0.54 -1.5 -1.5 -1.5 -1.5 0.54 -1.5 1.5 0.54 1.5 1.5 1.5Z"
          strokeWidth="1"></path>
    <path id="Vector 189" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round"
          d="M2 10.25v-1c0 -1.10457 0.89543 -2 2 -2h6c1.1046 0 2 0.89543 2 2v1" strokeWidth="1"></path>
    <path id="Vector 190" stroke={color || defaultColor} fill="none" strokeLinecap="round" strokeLinejoin="round" d="M7 4.75v5.5"
          strokeWidth="1"></path>
  </SvgIcon>
}

