import React from 'react';

import BatchControlIcon from '../../../assets/icons/BatchControlIcon';
import UserIcon from '../../../assets/icons/UserIcon';
import UsersIcon from '../../../assets/icons/UsersIcon';
import ProcedureIcon from '../../../assets/icons/ProcedureIcon';
import HospitalIcon from '../../../assets/icons/HospitalIcon';
import ProductIcon from '../../../assets/icons/ProductIcon';
import KitIcon from '../../../assets/icons/KitIcon';
import DocumentIcon from '../../../assets/icons/DocumentIcon';
import ItemIcon from '../../../assets/icons/ItemIcon';
import OptionIcon from '../../../assets/icons/OptionIcon';
import TerritoryIcon from '../../../assets/icons/TerritoryIcon';
import CalendarIcon from '../../../assets/icons/CalendarIcon';
import ParentKitIcon from '../../../assets/icons/ParentKitIcon';

import './modal-select.scss';

const ModalSelectInput = ({ count = 0, label = '', onClick, icon, values = [] }) => {
  const renderIcon = () => {
    switch (icon) {
      case 'user':
        return <UserIcon color='secondary' />;
      case 'users':
        return <UsersIcon color='secondary' />;
      case 'option':
        return <OptionIcon color='secondary' />;
      case 'procedure':
        return <ProcedureIcon color='secondary' />;
      case 'hospital':
        return <HospitalIcon color='secondary' />;
      case 'product':
        return <ProductIcon color='secondary' />;
      case 'kit':
        return <KitIcon color='secondary' />;
      case 'bom':
        return <DocumentIcon color='secondary' />;
      case 'items':
        return <ItemIcon color='secondary' />;
      case 'territory':
        return <TerritoryIcon color='secondary' />;
      case 'batch':
        return <BatchControlIcon color='secondary' />;
      case 'date':
        return <CalendarIcon color='secondary' />;
      case 'parent':
        return <ParentKitIcon color='secondary' />;
      case 'none':
        return null;
      default:
        return <UsersIcon color='secondary' />;
    }
  };

  return (
    <div
      className='modal-select pointer'
      style={{ alignItems: `${values && values.length > 1 ? 'flex-start' : 'center'}` }}
      onClick={onClick}
    >
      <div className='modal-select__count'>
        {values.map((value) => (
          <div key={`${value}_${Math.random()}`} className='m-t-sm m-b-sm'>
            {value}
          </div>
        ))}
      </div>
      <div
        className='modal-select__placeholder'
        style={{ marginTop: `${values && values.length > 1 ? '3px' : '0px'}` }}
      >
        <span>{label}</span>
        {renderIcon()}
      </div>
    </div>
  );
};

export default ModalSelectInput;
