import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import KitsTable from '../../../components/kits/KitsTable';
import KitsFilters from '../../../components/kits/KitsFilters';
import KitForm from '../../../components/kits/KitForm';
import Input from '../../../components/shared/Input';
import Modal, { ConfirmationModal, UploadModal } from '../../../components/shared/modal';
import Alert from '../../../components/shared/Alert';
import LoadScreen from '../../../components/load-screen';

import {
  getKits,
  createKit,
  updateKit,
  subscribeToKits,
  bulkDeleteKits,
  uploadKits,
  subscribeToManufacturers
} from '../../../actions/kitsActions';
import { getProducts } from '../../../actions/productsActions';

import { filterKits } from '../../../utils/table';

import { statusOptions } from '../../../constants/enums';
import routes from '../../../constants/routes';

const statusFilterOptions = [
  { label: 'Active', value: statusOptions.active },
  { label: 'Deactivated', value: statusOptions.deactivated }
];

const KitsPage = (props) => {
  const {
    kits,
    products,
    tenantColor,
    getProducts,
    createKit,
    updateKit,
    subscribeToKits,
    bulkDeleteKits,
    uploadKits,
    deletePermission,
    manufacturers = [],
    subscribeToManufacturers,
    isLoaded,
    items,
  } = props;

  const history = useHistory();

  const [isModalCreateOpen, toggleModalCreate] = useState(false);
  const [isModalDeleteOpen, toggleModalDelete] = useState(false);
  const [isUploadModalOpen, toggleUploadModal] = useState(false);
  const [isModalDuplicateOpen, toggleModalDuplicate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState(null);

  const [selectedKit, setSelectedKit] = useState(null);
  const [checkedKits, setCheckedKits] = useState([]);

  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    const unsubscribe = subscribeToKits();
    const unsubscribeToManufacturers = subscribeToManufacturers();

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
      unsubscribeToManufacturers();
    };
  }, []);

  const onLoad = async () => {
    await getProducts();
  };

  const onSelectKit = (kit) => {
    setSelectedKit(kit);
  };

  // Create kit
  const onCreateKit = async (formObj) => {
    setLoading(true);
    const kitObj = {
      name: formObj.name,
      kitId: formObj.kitId,
      type: formObj.type,
      allowQuantity: formObj.allowQuantity || false,
      isParent: formObj.isParent || false,
      products: formObj.products || [],
      manufacturers: [],
      version: '',
    };

    try {
      await createKit(kitObj);
      setLoading(false);
      toggleModalCreate(false);
      setSuccessMessage('Kit has been successfully created');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Update kit
  const onUpdateKit = async (formObj, logo) => {
    setLoading(true);
    const kitObj = {
      active: formObj.active,
      name: formObj.name,
      kitId: formObj.kitId,
      type: formObj.type,
      allowQuantity: formObj.allowQuantity || false,
      products: formObj.products || [],
      target: formObj.target && formObj.target.name ? formObj.target : null,
      manufacturers: formObj.manufacturers || [],
      version: formObj?.version || '',
      isParent: formObj?.isParent || false,
    };

    try {
      await updateKit(formObj.id, kitObj, logo);
      setLoading(false);
      setSuccessMessage('Kit has been successfully updated');
      setSelectedKit(formObj);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const onParentSettingsClick = async (kitId) => {
    try {
      await updateKit(kitId, { isParent: true });
      history.push(`${routes.KITS_SETTINGS}/${kitId}${routes.PARENT_KIT_SETTINGS}`);
    } catch (err) {
      console.error(err);
    }
  };


  // Delete checked kits
  const onDeleteKits = async () => {
    await bulkDeleteKits(checkedKits);

    toggleModalDelete(false);
    setCheckedKits([]);
    setSelectedKit(null);
  };

  const handleDeleteClick = () => {
    if (checkedKits.length) {
      toggleModalDelete(true);
    }
  };

  // Upload kits
  const onUpload = async (kits) => {
    setLoading(true);

    try {
      await uploadKits(kits);
      setLoading(false);
      setSuccessMessage('Kits have been successfully uploaded');
      toggleUploadModal(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // Duplicate kit
  const onDuplicateClick = async () => {
    if (!checkedKits || checkedKits.length !== 1) {
      return;
    }

    const kitId = checkedKits[0];
    const kit = kits.find((k) => k.id === kitId);
    setSelectedKit(kit);
    toggleModalDuplicate(true);
  };

  const onDuplicateKit = async (formObj) => {
    setLoading(true);
    const kitObj = {
      name: formObj.name,
      kitId: formObj.kitId,
      type: formObj.type,
      products: formObj.products || [],
      allowQuantity: formObj.allowQuantity || false,
      version: formObj?.version || ''
    };

    try {
      await createKit(kitObj);
      setLoading(false);
      toggleModalDuplicate(false);
      setSuccessMessage('Kit has been successfully duplicated');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // setCheckedKits([]);
  };

  const kitList = useMemo(() => {
    return kits.filter((kit) => filterKits(kit, search, statusFilter));
  }, [kits, search, statusFilter]);

  const handleCheck = (kitId) => {
    const checked = checkedKits.slice();

    if (checked.includes(kitId)) {
      const index = checked.indexOf(kitId);

      checked.splice(index, 1);
      setCheckedKits(checked);

      return;
    }

    checked.push(kitId);
    setCheckedKits(checked);
  };

  const handleCheckAll = () => {
    const checked = checkedKits.length === kitList.length;
    const temp = [];

    if (!checked) {
      kitList.forEach((kit) => {
        temp.push(kit.id);
      });

      setCheckedKits(temp);
      return;
    }

    setCheckedKits(temp);
  };

  return (
    <div className='settings-cmp__main'>
      {!isLoaded && <LoadScreen />}
      <span className='settings-title'>Kits</span>
      <div className='settings-cmp__body'>
        <div className='filters-container'>
          <KitsFilters
            onAddKitClick={() => toggleModalCreate(true)}
            onDelete={handleDeleteClick}
            status={statusFilter}
            setStatus={setStatusFilter}
            statuses={statusFilterOptions}
            onUploadClick={() => toggleUploadModal(true)}
            onDuplicateClick={onDuplicateClick}
            deletePermission={deletePermission}
          />
          <KitsTable
            onSelectKit={onSelectKit}
            kits={kitList}
            selectedKitId={selectedKit ? selectedKit.id : null}
            handleCheck={handleCheck}
            checkedKits={checkedKits}
            handleCheckAll={handleCheckAll}
            products={products}
            manufacturers={manufacturers}
          />
        </div>
        { kits && !!kits.length && (
          <div className='form-container'>
            <Input
              type='search'
              placeholder='Search Kits'
              value={search}
              onChange={handleSearch}
            />
            { selectedKit && (
              <KitForm
                initialValues={{
                  ...selectedKit,
                  logoName: selectedKit?.logo ? selectedKit?.logo?.fileName : '',
                  target: selectedKit.target || { name: '', value: 0, note: '' },
                  products: selectedKit?.products || [],
                  sections: selectedKit?.sections || [],
                  children: selectedKit?.children || [],
                }}
                buttonText='Save Changes'
                onSubmit={onUpdateKit}
                loading={loading}
                mode='update'
                products={products}
                kits={kits}
                manufacturers={manufacturers}
                onParentSettingsClick={onParentSettingsClick}
                items={items}
              />
            )}
          </div>
        )}
      </div>

      <ConfirmationModal
        open={isModalDeleteOpen}
        onClose={() => toggleModalDelete(false)}
        onSubmit={onDeleteKits}
        title='Are you sure you want to delete these kits?'
        submitText='Delete'
      />

      <Modal
        open={isModalCreateOpen}
        onClose={() => toggleModalCreate(false)}
      >
        <KitForm
          buttonText='Add Kit'
          onSubmit={onCreateKit}
          loading={loading}
          mode='create'
          onClose={() => toggleModalCreate(false)}
          kits={kits}
          items={items}
        />
      </Modal>

      <Modal
        open={isModalDuplicateOpen}
        onClose={() => toggleModalDuplicate(false)}
      >
        <KitForm
          initialValues={{ ...selectedKit, manufacturers: selectedKit?.manufacturers || [] }}
          buttonText='Duplicate'
          onSubmit={onDuplicateKit}
          loading={loading}
          mode='create'
          onClose={() => toggleModalDuplicate(false)}
          manufacturers={manufacturers}
          items={items}
        />
      </Modal>

      <UploadModal
        open={isUploadModalOpen}
        onClose={() => toggleUploadModal(false)}
        title='Upload Kits'
        tenantColor={tenantColor}
        onSubmit={onUpload}
        loading={loading}
        fields={['Name', 'Kit ID', 'Type']}
      />

      <Alert
        variant='success'
        message={successMessage}
        open={!!successMessage}
        onClose={() => setSuccessMessage(null)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    kits: state.kits.list,
    items: state.items.list,
    isLoaded: state.kits.isLoaded,
    products: state.products.list,
    manufacturers: state.kits?.manufacturers || [],
    tenantColor: state.tenant.currentTenant.colorPrimary,
    deletePermission: state.user.currentUser?.deletePermission,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getKits: () => dispatch(getKits()),
    getProducts: () => dispatch(getProducts()),
    createKit: (kit) => dispatch(createKit(kit)),
    updateKit: (id, kitData, logo) => dispatch(updateKit(id, kitData, logo)),
    subscribeToKits: () => dispatch(subscribeToKits()),
    bulkDeleteKits: (kitIds) => dispatch(bulkDeleteKits(kitIds)),
    uploadKits: (kits) => dispatch(uploadKits(kits)),
    subscribeToManufacturers: () => dispatch(subscribeToManufacturers())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KitsPage);
