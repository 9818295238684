import React from 'react';

import BatchControlIcon from '../../../assets/icons/BatchControlIcon';

import {
  caseStatusOptions,
  itemTypeOptions, kitItemTypes,
  setAllocationStatuses,
  setPositionOptions,
} from '../../../constants/enums';
import Button from '../../shared/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Checkbox from '../../shared/Checkbox';
import KitIcon from '../../../assets/icons/KitIcon';
import ItemIcon from '../../../assets/icons/ItemIcon';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const AddButton = ({
  variant,
  disabled,
  onClick,
  color
}) => (
  <Button
    type='outlined'
    height={25}
    width={25}
    disabled={disabled}
    onClick={onClick}
    borderColor={color}
  >
    {variant === 'minus' ? (
      <RemoveIcon fontSize='small' style={{ fill: disabled ? '#cccccc' : color }} />
    ) : (
      <AddIcon fontSize='small' style={{ fill: disabled ? '#cccccc' : color }} />
    )}
  </Button>
);


const ParentKitItemRow = (props) => {
  const { item, checked, onCheck, onQuantityChange, provided, isDragging } = props;

  const onMinusClick = () => {
    onQuantityChange(item.id, item.quantity - 1);
  };

  const onPlusClick = () => {
    onQuantityChange(item.id, item.quantity + 1);
  };

  return (
    <tr
      {...provided.draggableProps}
      ref={provided.innerRef}
      className={isDragging ? 'dragging-row' : ''}
    >
      <td style={{ width: '30px' }}>
        <Checkbox
          input={{
            checked: checked,
            onChange: () => onCheck(item.id)
          }}
          fontSize='small'
        />
      </td>
      <td style={{ width: '50px' }}>{item.type === kitItemTypes.KIT.value ? <KitIcon /> : <ItemIcon />}</td>
      <td>{item.code}</td>
      <td>{item.description}</td>
      <td>{item.itemType}</td>
      <td style={{ width: 100 }}>
        <div className="d-flex flex-end">
          <AddButton
            variant="minus"
            onClick={onMinusClick}
            disabled={item.quantity <= 1}
            color={setPositionOptions.CONSIGNED.color}
          />
          <div
            style={{
              // color: setPositionOptions.CONSIGNED.color,
              textAlign: 'center',
              marginLeft: 4,
              marginRight: 4,
              width: 25,
            }}
          >
            {item.quantity}
          </div>
          <AddButton
            variant="plus"
            onClick={onPlusClick}
            color={setPositionOptions.CONSIGNED.color}
          />
        </div>
      </td>
      <td {...provided.dragHandleProps} style={{ width: 24 }}>
        <DragIndicatorIcon />
      </td>
    </tr>
  );
};

export default ParentKitItemRow;
