import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import Button from '../shared/Button';
import Input from '../shared/Input';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import ModalSelect from '../shared/modal-select/ModalSelect';

import '../../styles/shared/form.scss';

const SetForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    kits,
    onClose,
    sets = [],
  } = props;

  const { number } = initialValues || {};

  const [isModalOpen, toggleModal] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.number) {
      errors.number = 'Required';
    }

    if (sets?.map((s) => s.number)?.includes(values?.number?.trim()) && mode === 'create') {
      errors.number = 'Duplicate';
    }

    return errors;
  };

  const getKitName = (kitIds) => {
    return kitIds.map((kitId) => {
      const kit = kits.find((item) => item.id === kitId);
      return kit ? kit.kitId : '';
    });
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={initialValues}
        subscription={{ pristine: true, invalid: true }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { number ? number : 'Add Set' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='m-b-sm'>
                <div className='field__title'>Set Number</div>
                <Field name='number'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Number'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>Container Size</div>
                <Field name='containerSize'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Size'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              { mode === 'update' && (
                <div>
                  <div className='field__title'>Kit ID</div>
                  {initialValues.caseAllocation ? (
                    <Field name='kit'>
                      {({ input, meta }) => (
                        <Input
                          name={input.name}
                          value={getKitName(input.value)}
                          type='text'
                          onChange={input.onChange}
                          disabled
                        />
                      )}
                    </Field>
                  ) : (
                    <Field name='kit'>
                      {({ input, meta }) => (
                        <div className='m-b-lg'>
                          <ModalSelectInput
                            onClick={() => toggleModal(true)}
                            count={input.value.length}
                            label='Edit Kit'
                            values={getKitName(input.value)}
                            icon='kit'
                          />

                          <ModalSelect
                            title='Add Kit'
                            listTitle='Kits'
                            options={kits?.filter((kit) => !kit?.isParent)?.map((kit) => ({ ...kit, name: kit.kitId, kitName: kit.name }))}
                            onChange={input.onChange}
                            selected={input.value}
                            onClose={() => toggleModal(false)}
                            isOpen={isModalOpen}
                            singleSelect
                            extraField='kitName'
                          />
                        </div>
                      )}
                    </Field>
                  )}
                </div>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default SetForm;
