import React, { useMemo, useCallback } from 'react';
import { sum } from 'lodash';
import moment from 'moment';

import WarningIcon from '@material-ui/icons/Error';

import { SetsPieChart } from '../../charts';

import { getDiffDays } from '../../../utils/date';

import {
  setAllocationStatuses,
  caseStatusOptions,
  kitVariantTypes,
  consumableStatuses,
} from '../../../constants/enums';

const { ASSIGNED, SHIPPED, TRANSFERRED, RETURNED, AVAILABLE, DISABLED, USAGE, CONFIRMED } = setAllocationStatuses;

const CaseSetsChart = (props) => {
  const { setsAllocation, datePassed, date, shipDate, noEquipmentToShip, status, deliveryAddress, kitVariant } = props;

  const notAssigned = useMemo(() => {
    return setsAllocation ? setsAllocation.filter((item) => item.status === AVAILABLE.value) : [];
  }, [setsAllocation]);  const assigned = useMemo(() => {
    return setsAllocation ? setsAllocation.filter((item) => item.status === ASSIGNED.value) : [];
  }, [setsAllocation]);
  const shipped = useMemo(() => {
    return setsAllocation ? setsAllocation.filter((item) => item.status === SHIPPED.value) : [];
  }, [setsAllocation]);
  const confirmed = useMemo(() => {
    return setsAllocation ? setsAllocation.filter((item) => item.status === CONFIRMED.value) : [];
  }, [setsAllocation]);
  const transferred = useMemo(() => {
    return setsAllocation ? setsAllocation.filter((item) => item.status === TRANSFERRED.value) : [];
  }, [setsAllocation]);
  const returned = useMemo(() => {
    return setsAllocation ? setsAllocation.filter((item) => item.status === RETURNED.value) : [];
  }, [setsAllocation]);
  const usage = useMemo(() => {
    return setsAllocation ? setsAllocation.filter((item) => item.status === USAGE.value) : [];
  }, [setsAllocation]);

  const getTotal = useCallback((array) => {
    return sum(array.map((item) => {
      if (item.consumables && item.consumables.length) {
        return item.consumables.length;
      }

      return item.sets ? item.sets.length : 0;
    }));
  }, [setsAllocation]);

  const getReturned = useCallback((returnedArr, usageArr) => {
    let result = getTotal(returnedArr);

    if (usageArr && usageArr.length) {
      const returnedConsumables = getReturnedConsumables(usageArr);
      result = result + returnedConsumables;
    }

    return result;
  }, [setsAllocation]);

  const getReturnedConsumables = useCallback((array = []) => {
    return sum(array.map((item) => {
      if (item?.consumables[0]?.id) {
        return item.consumables?.filter((c) => c.status !== consumableStatuses.USAGE.value)?.length;
      } else if (item?.consumables?.length && item?.usageConsumables) {
        return item.consumables?.filter((i) => !item?.usageConsumables?.includes(i))?.length;
      }

      return 0;
    }));
  }, [setsAllocation]);

  const getUsage = useCallback((array) => {
    if (kitVariant === kitVariantTypes.consignment) {
      return getTotal(usage);
    } else {
      return sum(array.map((item) => {
        if (item?.consumables[0]?.id) {
          return item.consumables?.filter((c) => c.status === consumableStatuses.USAGE.value)?.length;
        } else if (item?.consumables?.length && item?.usageConsumables?.length) {
          return item.usageConsumables?.length;
        }

        return 0;
      }));
    }
  }, [setsAllocation]);

  const data = datePassed ?
    [
      { id: TRANSFERRED.label, label: TRANSFERRED.label, value: getTotal(transferred), color: TRANSFERRED.color },
      { id: RETURNED.label, label: RETURNED.label, value: getReturned(returned, usage), color: RETURNED.color },
      { id: USAGE.label, label: USAGE.label, value: getUsage(usage), color: USAGE.color },
      { id: 'Not Returned', label: 'Not Returned', value: getTotal(kitVariant === kitVariantTypes.consignment ? [...confirmed, ...shipped] : shipped) + getTotal(assigned), color: '#eeeeee' },
    ].filter((item) => item.value !== 0) :
    [
      { id: ASSIGNED.label, label: ASSIGNED.label, value: getTotal(assigned), color: ASSIGNED.color },
      {
        id: kitVariant === kitVariantTypes.consignment ? CONFIRMED.label : SHIPPED.label,
        label: kitVariant === kitVariantTypes.consignment ? CONFIRMED.label : SHIPPED.label,
        value: getTotal(kitVariant === kitVariantTypes.consignment ? [...confirmed, ...shipped] : shipped),
        color: SHIPPED.color,
      },
      { id: 'Not Assigned', label: 'Not Assigned', value: notAssigned.length, color: '#eeeeee' },
    ].filter((item) => item.value !== 0);

  const shipDiff = useMemo(() => getDiffDays(shipDate, moment()), [shipDate]);
  const returnDate = useMemo(() => moment(date).add(7, 'days'), [date]);
  const returnDiff = useMemo(() => getDiffDays(returnDate, moment()), [returnDate]);

  const allShipped = useMemo(() => {
    return !datePassed && setsAllocation
      .filter((allocation) => allocation.status !== DISABLED.value)
      .every((allocation) => allocation.status === SHIPPED.value || allocation.status === CONFIRMED.value);
  }, [datePassed, setsAllocation]);

  const allReturned = useMemo(() => {
    return datePassed && setsAllocation
      .filter((allocation) => allocation.status !== DISABLED.value)
      .every((allocation) => [RETURNED.value, USAGE.value, TRANSFERRED.value]?.includes(allocation.status));
  }, [datePassed, setsAllocation]);

  return (
    <div className='sets-chart__container' style={{ maxHeight: deliveryAddress ? '338px' : '302px' }}>
      <div className='flex-1'>
        <SetsPieChart
          data={data}
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          padAngle={0}
          cornerRadius={0}
          transitionMode="endAngle"
        />
      </div>

      {datePassed ? (
        <div className='d-flex direction-column space-between align-end'>
          <div className='d-flex direction-column align-end width-100'>
            <div className='font-bold d-flex' style={{ color: returnDiff < 4 && !allReturned ? ASSIGNED.color : SHIPPED.color }}>
              {noEquipmentToShip || status === caseStatusOptions.request ? (
                <div>No equipment to return</div>
              ) : (
                <>
                  { returnDiff < 4 && !allReturned && <WarningIcon style={{ color: ASSIGNED.color, marginRight: 4 }} /> }
                  <div>
                    {allReturned ? (
                      <div>All resolved</div>
                    ) : (
                      returnDiff > 0 ? `${returnDiff} day(s) to return` : 'Overdue'
                    )}
                  </div>
                </>
              )}
            </div>
            <div className='secondary font-bold'>
              {returnDate.format('Do MMMM')}
            </div>
          </div>
          <div className='sets-chart__legend'>
            <div>
              <div style={{ background: TRANSFERRED.color }} />
              <div>{TRANSFERRED.label}</div>
            </div>
            {kitVariant !== kitVariantTypes.consignment && (
              <div>
                <div style={{ background: RETURNED.color }} />
                <div>{RETURNED.label}</div>
              </div>
            )}
            <div>
              <div style={{ background: USAGE.color }} />
              <div>{USAGE.label}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex direction-column space-between align-end'>
          <div className='d-flex direction-column align-end width-100'>
            <div className='font-bold d-flex' style={{ color: shipDiff < 4 && !allShipped ? ASSIGNED.color : SHIPPED.color }}>
              { shipDiff < 4 && !allShipped && <WarningIcon style={{ color: ASSIGNED.color, marginRight: 4 }} /> }
              {noEquipmentToShip || status === caseStatusOptions.request ? (
                <div>No equipment to {kitVariant === kitVariantTypes.consignment ? 'confirm' : 'ship'}</div>
              ) : (
                allShipped ? (
                  <div>All sets {kitVariant === kitVariantTypes.consignment ? 'confirmed' : 'shipped'}</div>
                ) : (
                  <div>{shipDiff > 0 ? `${shipDiff} day(s) to ${kitVariant === kitVariantTypes.consignment ? 'confirm' : 'ship'}` : 'Overdue'}</div>
                )
              )}
            </div>
            <div className='secondary font-bold'>
              {shipDate.format('Do MMM')}
            </div>
          </div>
          <div className='sets-chart__legend'>
            <div>
              <div style={{ background: ASSIGNED.color }} />
              <div>{ASSIGNED.label}</div>
            </div>
            <div>
              <div style={{ background: SHIPPED.color }} />
              <div>{kitVariant === kitVariantTypes.consignment ? CONFIRMED.label : SHIPPED.label}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaseSetsChart;
