import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '../../shared/Checkbox';
import AddButton from '../../shared/AddButton';

import {
  caseStatusOptions,
  setAllocationStatuses,
  kitPreferenceItemTypes,
  kitItemTypes,
} from '../../../constants/enums';
import Tooltip from '@material-ui/core/Tooltip';
import ParentKitIcon from '../../../assets/icons/ParentKitIcon';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '12px',
    padding: '4px 8px',
    borderRadius: 8
  }
}));

const KitPreferencesList = (props) => {
  const {
    kitPreference,
    kitsList = [],
    itemsList = [],
    selectedKits = [],
    selectedItems = [],
    status,
    noKitsChecked,
    onChangeKits,
    onChangeItems,
  } = props;

  const classes = useStyles();

  const getChildrenNames = (children) => {
    if (!children?.length) {
      return '';
    }
    return children?.map((item) => {
      const arr = item?.type === kitItemTypes.KIT.value ? kitsList : itemsList;
      const kitItem = arr?.find((i) => i.id === item.id);
      return kitItem?.code || kitItem?.name || '';
    }).join(', ');
  };

  return (
    <div className='m-t-sm m-b-sm'>
      <div>
        {kitPreference?.items?.map((preferenceItem) => {
          if (preferenceItem.type === kitPreferenceItemTypes.TITLE.value) {
            return <div className='font-bold m-t-md m-b-sm'>{preferenceItem.title}</div>;
          }

          if (preferenceItem.type === kitPreferenceItemTypes.KIT.value) {
            const kit = kitsList.find((k) => k.id === preferenceItem.value);
            const updatedKit = selectedKits.find((k) => k.id === preferenceItem.value);
            if (kit) {
              return (
                <div className='md-user-info' key={preferenceItem.value}>
                  <Checkbox
                    disabled={noKitsChecked || (
                      status && (status === caseStatusOptions.completed || status === caseStatusOptions.overdue) && (
                        updatedKit?.status !== setAllocationStatuses.AVAILABLE.value || !updatedKit
                      )
                    )}
                    input={{
                      checked: !!selectedKits.find((k) => k.id === preferenceItem.value),
                      onChange: (e) => {
                        if (e.target.checked) {
                          onChangeKits([
                            ...selectedKits,
                            { id: preferenceItem.value, quantity: updatedKit && updatedKit.quantity ? updatedKit.quantity : 1 }
                          ]);
                        } else {
                          if (updatedKit.status && updatedKit.status !== setAllocationStatuses.AVAILABLE.value) {
                            return;
                          }
                          onChangeKits(selectedKits.filter((k) => k.id !== preferenceItem.value));
                        }
                      }
                    }}
                    fontSize='small'
                    label={kit.name}
                    fontWeight='regular'
                  />

                  {!!selectedKits.find((k) => k.id === preferenceItem.value) && kit.allowQuantity && (
                    <div className='d-flex'>
                      <AddButton
                        width={24}
                        height={24}
                        variant='remove'
                        disabled={updatedKit?.status && updatedKit?.status !== setAllocationStatuses.AVAILABLE.value}
                        onClick={() => {
                          const kits = [...selectedKits];
                          const updatedKit = kits.find((k) => k.id === preferenceItem.value);
                          if (updatedKit && updatedKit.quantity > 1) {
                            updatedKit.quantity = updatedKit.quantity - 1;
                            onChangeKits(kits);
                          }
                        }}
                      />
                      <div
                        style={{ width: 16 }}
                        className='m-l-md m-r-md text-center'
                      >
                        {selectedKits?.find((k) => k.id === preferenceItem.value)?.quantity || 1}
                      </div>
                      <AddButton
                        width={24}
                        height={24}
                        disabled={updatedKit?.status && updatedKit?.status !== setAllocationStatuses.AVAILABLE.value}
                        onClick={() => {
                          const kits = [...selectedKits];
                          const updatedKit = kits.find((k) => k.id === preferenceItem.value);
                          updatedKit.quantity = updatedKit && updatedKit.quantity ? updatedKit.quantity + 1 : 2;
                          onChangeKits(kits);
                        }}
                      />
                    </div>
                  )}

                  {!!kit.isParent && <div className='m-l-md'>
                    <Tooltip
                      title={getChildrenNames(kit.children)}
                      aria-label='parent-kit-icon'
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <div>
                        <ParentKitIcon />
                      </div>
                    </Tooltip>
                  </div>}
                </div>
              );
            }
            return null;
          }

          if (preferenceItem.type === kitPreferenceItemTypes.ITEM.value) {
            const item = itemsList.find((i) => i.id === preferenceItem.value);
            const updatedItem = selectedItems.find((i) => i.id === preferenceItem.value);
            if (item) {
              return (
                <div className='md-user-info' key={preferenceItem.value}>
                  <Checkbox
                    disabled={noKitsChecked || (
                      status && (status === caseStatusOptions.completed || status === caseStatusOptions.overdue) && (
                        updatedItem?.status !== setAllocationStatuses.AVAILABLE.value || !updatedItem
                      )
                    )}
                    input={{
                      checked: !!selectedItems.find((i) => i.id === preferenceItem.value),
                      onChange: (e) => {
                        if (e.target.checked) {
                          onChangeItems([
                            ...selectedItems,
                            { id: item.id, quantity: 1 }
                          ]);
                        } else {
                          if (updatedItem.status && updatedItem.status !== setAllocationStatuses.AVAILABLE.value) {
                            return;
                          }
                          onChangeItems(selectedItems?.filter((i) => i.id !== item.id));
                        }
                      }
                    }}
                    fontSize='small'
                    label={`${item.checklistDescription || item.description} – ${item.code}`}
                    fontWeight='regular'
                  />

                  {!!selectedItems.find((i) => i.id === preferenceItem.value) && (
                    <div className='d-flex'>
                      <AddButton
                        width={24}
                        height={24}
                        variant='remove'
                        disabled={updatedItem?.status && updatedItem?.status !== setAllocationStatuses.AVAILABLE.value}
                        onClick={() => {
                          const items = [...selectedItems];
                          const updatedItem = items.find((k) => k.id === preferenceItem.value);
                          if (updatedItem && updatedItem.quantity > 1) {
                            updatedItem.quantity = updatedItem.quantity - 1;
                            onChangeItems(items);
                          }
                        }}
                      />
                      <div
                        style={{ width: 16 }}
                        className='m-l-md m-r-md text-center'
                      >
                        {selectedItems?.find((i) => i.id === preferenceItem.value)?.quantity || 1}
                      </div>
                      <AddButton
                        width={24}
                        height={24}
                        disabled={updatedItem?.status && updatedItem?.status !== setAllocationStatuses.AVAILABLE.value}
                        onClick={() => {
                          const items = [...selectedItems];
                          const updatedItem = items.find((i) => i.id === preferenceItem.value);
                          updatedItem.quantity = updatedItem && updatedItem.quantity ? updatedItem.quantity + 1 : 2;
                          onChangeItems(items);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            }
            return null;
          }

          return null;
        })}
      </div>
    </div>
  );
};

export default KitPreferencesList;