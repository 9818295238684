import React from 'react';

import CalendarIcon from '../../assets/icons/CalendarMarkIcon';
import TableIcon from '../../assets/icons/TableIcon';

import FilterButton from './FilterButton';
import Button from '../shared/Button';
import Switch from '../shared/Switch';

import './cases.scss';

const views = {
  TABLE: 'TABLE',
  CALENDAR: 'CALENDAR',
};

const CasesFilters = (props) => {
  const {
    onAddCaseClick,
    view,
    setView,
    statusFilter,
    onStatusFilterChange,
    caseWriteDisabled = false,
    showCanceled,
    onToggle,
    disabled,
  } = props;

  return (
    <div className='page__filters--main'>
      <div className='page__actions'>
        <Button
          text='Add Case'
          type='submit'
          onClick={onAddCaseClick}
          disabled={caseWriteDisabled}
        />

        {view === views.CALENDAR ? (
          <Button
            type='icon'
            onClick={() => setView(views.TABLE)}
            // disabled={caseWriteDisabled || disabled}
          >
            <TableIcon
              // color={caseWriteDisabled ? '#9e9e9e' : '#000000'}
            />
          </Button>
        ) : (
          <Button
            type='icon'
            onClick={() => setView(views.CALENDAR)}
            // disabled={caseWriteDisabled || disabled}
          >
            <CalendarIcon
              // color={caseWriteDisabled ? '#9e9e9e' : '#000000'}
            />
          </Button>
        )}

        <div className='status-filters'>
          {/*<div className='m-r-lg d-flex'>*/}
          {/*  <div className='secondary font-bold m-r-md'>*/}
          {/*    Show Canceled*/}
          {/*  </div>*/}
          {/*  <Switch*/}
          {/*    value={showCanceled}*/}
          {/*    onChange={onToggle}*/}
          {/*  />*/}
          {/*</div>*/}

          { Object.keys(statusFilter).map((key) => (
            <FilterButton
              key={key}
              status={key}
              value={statusFilter[key]}
              onClick={onStatusFilterChange}
            />
          ))}
        </div>
      </div>
    </div>
  )
};

export default CasesFilters;
