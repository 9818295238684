import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import ShippingIcon from '../../../assets/icons/ShipIcon';
import ReturnIcon from '../../../assets/icons/ReturnIcon';

import { ShippedKitsTable, ReturnedKitsTable } from '../../../components/kits';
import ActionButton from '../../../components/shared/ActionButton';
import Input from '../../../components/shared/Input';
import LoadScreen from '../../../components/load-screen';
import KitsFiltersModal from '../../../components/kits/KitsFiltersModal';
import KitFiltersList from '../../../components/kits/KitFiltersList';

import {
  toggleShipped,
  setFilters,
  toggleReturned,
  setCaseView,
  toggleConsignment,
  toggleLoan,
  toggleCanceled,
} from '../../../actions/casesActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getProcedures } from '../../../actions/proceduresActions';
import { subscribeToSets } from '../../../actions/setsActions';
import { getKits } from '../../../actions/kitsActions';
import { setOrder, setOrderBy, setPage, setRowsPerPage, setSearch, setActiveTab, getCases } from '../../../actions/shippingActions';

import { useLoading } from '../../../hooks';

import { kitVariantTypes, setAllocationStatuses, caseStatusOptions } from '../../../constants/enums';
import routes from '../../../constants/routes';

import './kits-page.scss';

const { ASSIGNED, SHIPPED, RETURNED, TRANSFERRED, AVAILABLE, USAGE } = setAllocationStatuses;

const KitsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const surgeons = useSelector((state) => state.surgeons.list);
  const hospitals = useSelector((state) => state.hospitals.list);
  const procedures = useSelector((state) => state.procedures.list);
  const hideShipped = useSelector((state) => state.cases.hideShipped);
  const hideReturned = useSelector((state) => state.cases.hideReturned);
  const hideConsignment = useSelector((state) => state.cases.hideConsignment);
  const showCanceled = useSelector((state) => state.cases.showCanceled);
  const hideLoan = useSelector((state) => state.cases.hideLoan);
  const filters = useSelector((state) => state.cases.filters);

  const cases = useSelector((state) => state.shipping.list);
  const isLoaded = useSelector((state) => state.shipping.isLoaded);
  const order = useSelector((state) => state.shipping.order);
  const orderBy = useSelector((state) => state.shipping.orderBy);
  const page = useSelector((state) => state.shipping.page);
  const rowsPerPage = useSelector((state) => state.shipping.rowsPerPage);
  const search = useSelector((state) => state.shipping.search);
  const activeTab = useSelector((state) => state.shipping.activeTab);

  const [isOpen, toggleModal] = useState(false);

  const { loading, startLoading, stopLoading } = useLoading();

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToSets());

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  const onLoad = async () => {
    if (!isLoaded) {
      startLoading();
    }
    try {
      await Promise.all([
        dispatch(getCases()),
        dispatch(getKits()),
        dispatch(getSurgeons()),
        dispatch(getHospitals()),
        dispatch(getProcedures()),
      ]);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onRowClick = (caseId) => {
    dispatch(setCaseView('SETS'));
    history.push(`${routes.CASES}/${caseId}?from=shipping`);
  };

  const onRemoveFilter = (filter) => {
    if (filter === 'consignment') {
      dispatch(toggleConsignment());
    }
    if (filter === 'loan') {
      dispatch(toggleLoan());
    }
    if (filter === 'shipped') {
      dispatch(toggleShipped());
    }
    if (filter === 'surgeon') {
      dispatch(setFilters({ surgeon: '' }));
    }
    if (filter === 'hospital') {
      dispatch(setFilters({ hospital: '' }));
    }
    if (filter === 'state') {
      dispatch(setFilters({ state: '' }));
    }
    if (filter === 'canceled') {
      dispatch(toggleCanceled(false));
    }
  };

  const getSurgeonName = useCallback((surgeonId) => {
    const surgeon = surgeons.find((surgeon) => surgeon.id === surgeonId);
    return surgeon ? `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}` : '';
  }, [surgeons, filters]);

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.name : '';
  }, [hospitals, filters]);

  // Memoized data

  const list = useMemo(() => {
    let result = [...cases];

    if (hideConsignment) {
      result = result.filter((item) => item.kitVariant !== kitVariantTypes.consignment);
    }

    if (hideLoan) {
      result = result.filter((item) => item.kitVariant !== kitVariantTypes.loan);
    }

    if (filters.surgeon) {
      result = result.filter((item) => filters.surgeon === item.surgeon);
    }

    if (filters.hospital) {
      result = result.filter((item) => filters.hospital === item.hospital);
    }

    if (filters.state) {
      const stateHospitals = hospitals?.filter((h) => h.state === filters.state);
      result = result.filter((item) => stateHospitals?.map((h) => h.id)?.includes(item.hospital));
    }

    return result.filter((item) => item?.patientReference?.toLowerCase()?.includes(search.toLowerCase()) || item?.id?.toLowerCase()?.includes(search.toLowerCase()));
  }, [search, cases, hideConsignment, hideLoan, filters, hospitals]);

  const shippedCases = useMemo(() => {
    return list.filter((item) => {
      const date = moment().subtract(7, 'days').endOf('day');
      const caseDate = moment(item.date).endOf('day');

      const isShipped = item.setsAllocation && !item.setsAllocation.some((allocation) => [ASSIGNED.value, AVAILABLE.value].includes(allocation.status));
      return !!item.active && !date.isAfter(caseDate) && !isShipped;
    });
  }, [list]);

  const returnedCases = useMemo(() => {
    return list.filter((item) => {
      let filter;

      // if (hideReturned) {
      //   const total = item.setsAllocation ? item.setsAllocation.filter((item) => [ASSIGNED.value, SHIPPED.value, RETURNED.value].includes(item.status)) : [];
      //   const isReturned = total.every((allocation) => allocation.status === RETURNED.value);
      //
      //   filter = !isReturned;
      // } else {
      //   filter = item.setsAllocation.some((allocation) => [ASSIGNED.value, SHIPPED.value, RETURNED.value, TRANSFERRED.value].includes(allocation.status));
      // }

      const total = item.setsAllocation ? item.setsAllocation.filter((item) => [ASSIGNED.value, SHIPPED.value, RETURNED.value, USAGE.value].includes(item.status)) : [];
      const isReturned = total.every((allocation) => allocation.status === RETURNED.value || allocation.status === USAGE.value);
      const isShipped = total.every((allocation) => allocation.status === SHIPPED.value || allocation.status === RETURNED.value || allocation.status === USAGE.value);
      const hasShippedKits = item.setsAllocation && !!item.setsAllocation.filter((allocation) => [SHIPPED.value, RETURNED.value, USAGE.value, TRANSFERRED.value].includes(allocation.status)).length;

      if (hideShipped) {
        const today = moment().startOf('day');
        const caseDate = moment(item.date).endOf('day');

        filter = (!isShipped || today.isAfter(caseDate)) && item.setsAllocation.some((allocation) => [ASSIGNED.value, SHIPPED.value, RETURNED.value, USAGE.value, TRANSFERRED.value].includes(allocation.status));
      } else {
        filter = item.setsAllocation.some((allocation) => [ASSIGNED.value, SHIPPED.value, RETURNED.value, USAGE.value, TRANSFERRED.value].includes(allocation.status));
      }

      if (item.kitVariant === kitVariantTypes.consignment) {
        filter = item.status !== caseStatusOptions.completed;
      }

      return filter && hasShippedKits && !isReturned;
    });
  }, [list, hideShipped]);

  const states = useMemo(() => {
    const list = [];
    hospitals?.forEach((hospital) => {
      if (!list?.includes(hospital.state)) {
        list.push(hospital.state);
      }
    });
    return [
      { key: 'all', label: 'All', value: '' },
      ...list?.map((state, index) => ({
        key: `${state}_${index}`,
        label: state,
        value: state,
      }))
    ];
  }, [hospitals]);

  return (
    <div className='page-container kits-page'>
      {loading && <LoadScreen />}

      <div className='d-flex space-between'>
        <div className='d-flex'>
          <div className='page-title'>
            Shipping
          </div>
        </div>
      </div>

      <div className='kits-page__body'>
        <div className='d-flex space-between m-b-md'>
          <div className='d-flex'>
            <div className='m-r-md'>
              <ActionButton
                onClick={() => dispatch(setActiveTab(0))}
                bgColor={activeTab === 0 ? setAllocationStatuses.SHIPPED.color : '#dddddd'}
              >
                <ShippingIcon color="#ffffff" />
                Ship
              </ActionButton>
            </div>
            <div className='m-l-md'>
              <ActionButton
                onClick={() => dispatch(setActiveTab(1))}
                bgColor={activeTab === 1 ? setAllocationStatuses.RETURNED.color : '#dddddd'}
              >
                <ReturnIcon color="#ffffff" />
                Return
              </ActionButton>
            </div>
          </div>

          <div className='d-flex flex-end' style={{ width: '40%' }}>
            <div className='flex-1' style={{ maxWidth: 500 }}>
              <Input
                type='search'
                placeholder='Search Cases (Patient ref or Case #)'
                value={search}
                onChange={(e) => dispatch(setSearch(e.target.value))}
              />
            </div>
          </div>
        </div>

        <KitFiltersList
          onClick={() => toggleModal(true)}
          hideShipped={hideShipped}
          hideReturned={hideReturned}
          hideConsignment={hideConsignment}
          hideLoan={hideLoan}
          activeTab={activeTab}
          surgeon={getSurgeonName(filters?.surgeon)}
          hospital={getHospitalName(filters?.hospital)}
          state={filters.state}
          onDelete={onRemoveFilter}
          showCanceled={showCanceled}
        />

        {activeTab === 0 ? (
          <ShippedKitsTable
            cases={shippedCases}
            onRowClick={(item) => onRowClick(item.id)}
            surgeons={surgeons}
            hospitals={hospitals}
            procedures={procedures}
            order={order}
            setOrder={(value) => dispatch(setOrder(value))}
            orderBy={orderBy}
            setOrderBy={(value) => dispatch(setOrderBy(value))}
            page={page}
            setPage={(value) => dispatch(setPage(value))}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(value) => dispatch(setRowsPerPage(value))}
          />
        ) : (
          <ReturnedKitsTable
            cases={returnedCases}
            onRowClick={(item) => onRowClick(item.id)}
            surgeons={surgeons}
            hospitals={hospitals}
            procedures={procedures}
            order={order}
            setOrder={(value) => dispatch(setOrder(value))}
            orderBy={orderBy}
            setOrderBy={(value) => dispatch(setOrderBy(value))}
            page={page}
            setPage={(value) => dispatch(setPage(value))}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={(value) => dispatch(setRowsPerPage(value))}
          />
        )}
      </div>

      <KitsFiltersModal
        open={isOpen}
        onClose={() => toggleModal(false)}
        activeTab={activeTab}
        hideShipped={hideShipped}
        hideReturned={hideReturned}
        hideConsignment={hideConsignment}
        hideLoan={hideLoan}
        showCanceled={showCanceled}
        toggleShipped={() => dispatch(toggleShipped())}
        toggleReturned={() => dispatch(toggleReturned())}
        toggleConsignment={() => dispatch(toggleConsignment())}
        toggleCanceled={(value) => dispatch(toggleCanceled(value))}
        toggleLoan={() => dispatch(toggleLoan())}
        surgeon={filters.surgeon}
        hospital={filters.hospital}
        state={filters.state}
        onSurgeonChange={(value) => dispatch(setFilters({ surgeon: value }))}
        onHospitalChange={(value) => dispatch(setFilters({ hospital: value }))}
        onStateChange={(value) => dispatch(setFilters({ state: value }))}
        surgeons={surgeons}
        hospitals={hospitals}
        states={states}
      />
    </div>
  );
};

export default KitsPage;
