import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '../../shared/Checkbox';
import AddButton from '../../shared/AddButton';

import ParentKitIcon from '../../../assets/icons/ParentKitIcon';

import { caseStatusOptions, kitItemTypes, setAllocationStatuses } from '../../../constants/enums';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '12px',
    padding: '4px 8px',
    borderRadius: 8
  }
}));

const KitsList = (props) => {
  const { kitType, kitsList, status, noKitsChecked, input, kits = [], items = [] } = props;
  const value = input.value || [];
  const classes = useStyles();

  const getChildrenNames = (children) => {
    if (!children?.length) {
      return '';
    }
    return children?.map((item) => {
      const arr = item?.type === kitItemTypes.KIT.value ? kits : items;
      const kitItem = arr?.find((i) => i.id === item.id);
      return kitItem?.code || kitItem?.name || '';
    }).join(', ');
  };

  return (
    <div key={kitType.value} className='m-t-sm m-b-sm'>
      <div className='font-bold m-b-sm'>{kitType.label}</div>
      <div>
        {kitsList.filter((kit) => kit.type === kitType.value).map((kit) => {
          const updatedKit = value.find((item) => item.id === kit.id);
          const parentKit = kits.find((item) => item?.children?.map((k) => k?.id)?.includes(kit.id));
          return (
            <div className='md-user-info' key={kit.id}>
              <Checkbox
                disabled={
                  noKitsChecked || (
                  status && (status === caseStatusOptions.completed || status === caseStatusOptions.overdue) && (
                    updatedKit?.status !== setAllocationStatuses.AVAILABLE.value || !updatedKit
                  )) ||
                  (!!value?.find((k) => k?.id === parentKit?.id)
                )}
                input={{
                  ...input,
                  checked: !!value.find((item) => item.id === kit.id),
                  onChange: (e) => {
                    if (e.target.checked) {
                      input.onChange([
                        ...value,
                        { id: kit.id, quantity: updatedKit && updatedKit.quantity ? updatedKit.quantity : 1 }
                      ]);
                    } else {
                      if (updatedKit.status && updatedKit.status !== setAllocationStatuses.AVAILABLE.value) {
                        return;
                      }
                      input.onChange(value.filter((item) => item.id !== kit.id));
                    }
                  }
                }}
                fontSize='small'
                label={kit.name}
                fontWeight='regular'
              />

              {!!value.find((item) => item.id === kit.id) && kit.allowQuantity && !kit.isParent && (
                <div className='d-flex'>
                  <AddButton
                    width={24}
                    height={24}
                    variant='remove'
                    disabled={updatedKit?.status && updatedKit?.status !== setAllocationStatuses.AVAILABLE.value}
                    onClick={() => {
                      const kits = [...value];
                      const updatedKit = kits.find((item) => item.id === kit.id);
                      if (updatedKit && updatedKit.quantity > 1) {
                        updatedKit.quantity = updatedKit.quantity - 1;
                        input.onChange(kits);
                      }
                    }}
                  />
                  <div
                    style={{ width: 16 }}
                    className='m-l-md m-r-md text-center'
                  >
                    {value?.find((item) => item.id === kit.id)?.quantity || 1}
                  </div>
                  <AddButton
                    width={24}
                    height={24}
                    disabled={updatedKit?.status && updatedKit?.status !== setAllocationStatuses.AVAILABLE.value}
                    onClick={() => {
                      const kits = [...value];
                      const updatedKit = kits.find((item) => item.id === kit.id);
                      updatedKit.quantity = updatedKit && updatedKit.quantity ? updatedKit.quantity + 1 : 2;
                      input.onChange(kits);
                    }}
                  />
                </div>
              )}

              {!!kit.isParent && <div className='m-l-md'>
                <Tooltip
                  title={getChildrenNames(kit.children)}
                  aria-label='parent-kit-icon'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <div>
                    <ParentKitIcon />
                  </div>
                </Tooltip>
              </div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default KitsList;