import React, { useMemo, useState } from 'react';

import DeleteIcon from '../../../../assets/icons/DeleteIcon';
import EditIcon from '../../../../assets/icons/EditIcon';
import DoneIcon from '../../../../assets/icons/CheckIcon';

import { setAllocationStatuses } from '../../../../constants/enums';

const { RETURNED, SHIPPED } = setAllocationStatuses;

const VALUES = ['GTIN', 'REF', 'LOT', 'EXP', 'DESC'];

const SplitModalUsageItem = (props) => {
  const { item, onApproveClick, onSave, onDelete, orderClosed, tenantColor, users = [], tags = [] } = props;
  const { id, values = [], quantity, approved, userId } = item;

  const getValue = (values = [], key) => {
    if (!values || !values.length || !key) {
      return '';
    }

    const value = values?.find((item) => item.label === key);
    return value?.value || '';
  };

  const [editMode, toggleEditMode] = useState(false);

  const [data, setData] = useState({
    GTIN: getValue(values, 'GTIN'),
    REF: getValue(values, 'REF'),
    LOT: getValue(values, 'LOT'),
    EXP: getValue(values, 'EXP'),
    DESC: getValue(values, 'DESC'),
    quantity,
  });

  const onSaveClick = () => {
    onSave({
      ...item,
      gtin: data.GTIN || '',
      ref: data.REF || '',
      lot: data.LOT || '',
      exp: data.EXP || '',
      desc: data.DESC || '',
      quantity: data.quantity || '',
    });
    toggleEditMode(false);
  };

  const userName = useMemo(() => {
    if (!userId) {
      return '';
    }
    const user = users?.find((u) => u.uid === userId);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [userId, users])

  const tag = useMemo(() => tags?.find((t) => t.id === item.tag), [item, tags]);

  return (
    <div className='d-flex space-between'>
      <div>
        {!!tag && (
          <div style={{ color: tag.color }} className='m-b-sm font-bold'>
            {tag.name}
          </div>
        )}
        {VALUES?.map((label) => {
          if (getValue(values, label) || editMode) {
            return (
              <div className='m-b-sm'>
                <div className='secondary font-bold'>
                  {label}
                </div>
                <div className='font-bold' style={{ wordBreak: 'break-word' }}>
                  {editMode ? (
                    <input
                      className='minimized-input'
                      type='text'
                      value={data[label]}
                      onChange={(e) => setData({ ...data, [label]: e.target?.value })}
                    />
                  ) : (
                    getValue(values, label)
                  )}
                </div>
              </div>
            );
          }
          return null;
        })}
        {!!userName && (
          <div className='m-t-sm secondary'>Added by {userName}</div>
        )}
      </div>
      <div className='d-flex direction-column space-between align-end height-100'>
        <div className='pointer'>
          {!orderClosed && (
            editMode ? (
              <div className='d-flex'>
                <DeleteIcon color="#d32f2f" onClick={() => onDelete(id)} className='m-r-md' />
                <DoneIcon color={tenantColor} onClick={onSaveClick} />
              </div>
            ) : (
              <EditIcon color={tenantColor} onClick={() => toggleEditMode(true)} />
            )
          )}
        </div>
        <div className='text-right'>
          <div className='text-right'>
            <span className='font-bold secondary m-r-md'>QTY</span>
            {editMode ? (
              <input
                className='quantity-input'
                type='number'
                value={data.quantity}
                onChange={(e) => setData({ ...data, quantity: e.target?.value })}
              />
            ) : (
              <span className='font-bold'>{quantity}</span>
            )}
          </div>
          <div
            className='primary font-bold m-b-sm pointer'
            style={{ color: approved ? SHIPPED.color : RETURNED.color }}
            onClick={() => onApproveClick(id, !approved)}
          >
            {approved ? 'Approved' : 'Approve'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitModalUsageItem;
