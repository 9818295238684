import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step3Additional from './Step3Additional';
import Step4 from './Step4';
import Step5 from './Step5';
import KitBOM from './KitBOM';
import Modal from '../../shared/modal';

import { setFormStep } from '../../../actions/casesActions';
import { getSurgeons } from '../../../actions/surgeonsActions';
import { getHospitals } from '../../../actions/hospitalsActions';
import { getProcedures, getProcedureOptions } from '../../../actions/proceduresActions';
import { getProducts } from '../../../actions/productsActions';
import { getKits } from '../../../actions/kitsActions';
import { getItems } from '../../../actions/itemsActions';
import { getFlows } from '../../../actions/flowsActions';
import { getSets } from '../../../actions/setsActions';

import { useLoading } from '../../../hooks';

import userRoles from '../../../constants/userRoles';
import { kitVariantTypes } from '../../../constants/enums';

const CaseModal = (props) => {
  const {
    open,
    onClose,
    activeCase = {},
    mode = 'create',
    surgeons,
    hospitals,
    procedures,
    procedureOptions,
    products,
    kits,
    items,
    flows = [],
    sets = [],
    userRole,
    userAddresses,
    onSubmit,
    editingBy,
    currentTenant,
  } = props;

  const dispatch = useDispatch();

  const step = useSelector((state) => state?.activeCase?.formStep);
  const connectedTenants = useSelector((state) => state?.user?.currentUser?.connectedTenants) || [];
  const tenants = useSelector((state) => state?.tenant?.list) || [];
  const currentUser = useSelector((state) => state.user.currentUser);
  const tenantColor = useSelector((state) => state?.tenant?.currentTenant?.colorPrimary);

  const setStep = (newStep) => dispatch(setFormStep(newStep));

  const [connectedSurgeons, setConnectedSurgeons] = useState([]);
  const [connectedHospitals, setConnectedHospitals] = useState([]);
  const [connectedProcedures, setConnectedProcedures] = useState([]);
  const [connectedProcedureOptions, setConnectedProcedureOptions] = useState([]);
  const [connectedProducts, setConnectedProducts] = useState([]);
  const [connectedKits, setConnectedKits] = useState([]);
  const [connectedItems, setConnectedItems] = useState([]);
  const [connectedFlows, setConnectedFlows] = useState([]);
  const [connectedSets, setConnectedSets] = useState([]);

  const [fetching, setFetching] = useState(false)
  const [selected, setSelected] = useState(currentTenant?.id);
  const [selectedKit, setSelectedKit] = useState(null);

  const [formData, setFormData] = useState({});

  const { loading, startLoading, stopLoading } = useLoading(false);

  useEffect(() => {
    if (mode === 'update' && activeCase && open && !formData?.deliveryAddress) {
      setFormData({
        ...activeCase,
        deliveryAddress: activeCase.deliveryAddress ? activeCase.deliveryAddress.id : null,
      });
    }
  }, [activeCase, open]);

  const handleClose = () => {
    onClose();
    setFormData({});

    if (mode === 'create' && userRole === userRoles.SALES_REP.name && !!connectedTenants?.length) {
      setStep('company');
    } else {
      setStep(0);
    }
  };

  const isConnected = useMemo(() => (
    userRole === userRoles.SALES_REP.name && mode === 'create' && selected !== currentTenant?.id
  ), [mode, userRole, selected]);

  const handleSubmit = async () => {
    startLoading();

    try {
      await onSubmit(formData, isConnected ? selected : null);
      handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const goToKit = (kitId) => {
    setSelectedKit(kitId);
    dispatch(setStep('kit'));
  };

  const onBack = () => {
    dispatch(setStep(5));
    setSelectedKit(null);
  };

  const onSelectCompany = async () => {
    if (selected !== currentTenant?.id) {
      setFetching(true);

      try {
        const conSurgeons = await dispatch(getSurgeons(selected));
        const conHospitals = await dispatch(getHospitals(selected));
        const conProcedures = await dispatch(getProcedures(selected));
        const conProcedureOptions = await dispatch(getProcedureOptions(selected));
        const conProducts = await dispatch(getProducts(selected));
        const conKits = await dispatch(getKits(selected));
        const conItems = await dispatch(getItems(true, selected));
        const conFlows = await dispatch(getFlows(selected));
        const conSets = await dispatch(getSets(selected));

        setConnectedSurgeons(conSurgeons || []);
        setConnectedHospitals(conHospitals || []);
        setConnectedProcedures(conProcedures || []);
        setConnectedProcedureOptions(conProcedureOptions || []);
        setConnectedProducts(conProducts || []);
        setConnectedKits(conKits || []);
        setConnectedItems(conItems || []);
        setConnectedFlows(conFlows || []);
        setConnectedSets(conSets || []);
      } catch (err) {
        console.error(err);
      } finally {
        setFetching(false);
      }
    }

    setStep(0);
  };

  const renderStep = () => {
    switch (step) {
      case 'company':
        return (
          <Step0
            currentTenant={currentTenant}
            tenants={tenants}
            connectedTenants={connectedTenants}
            loading={fetching}
            onClickNext={onSelectCompany}
            selected={selected}
            setSelected={setSelected}
          />
        )
      case 0:
        return (
          <Step1
            initialValues={formData}
            onClickNext={(formObj) => {
              setFormData({ ...formData, ...formObj });
              setStep(1);
            }}
            onClose={handleClose}
            surgeons={isConnected ? connectedSurgeons : surgeons}
            hospitals={isConnected ? connectedHospitals : hospitals}
            procedures={isConnected ? connectedProcedures : procedures}
            procedureOptions={isConnected ? connectedProcedureOptions : procedureOptions}
            flows={isConnected ? connectedFlows : flows}
            status={activeCase.status}
            mode={mode}
            currentUser={currentUser}
          />
        );
      case 1:
        return <Step2
          initialValues={formData}
          onClickNext={(formObj) => {
            setFormData({ ...formData, ...formObj });
            setStep(2);
          }}
          onClickBack={() => setStep(0)}
          onClose={handleClose}
          products={isConnected ? connectedProducts : products}
          status={activeCase.status}
        />;
      case 2:
        return <Step3
          initialValues={formData}
          onClickNext={(formObj, kitPreference) => {
            setFormData({ ...formData, ...formObj });
            if ((formObj.kitVariant === kitVariantTypes.consignment && (formObj.kits?.length || formObj.noEquipmentToShip)) || kitPreference) {
              setStep(3);
            } else {
              setStep(4);
            }
          }}
          onClickBack={() => setStep(1)}
          onClose={handleClose}
          kits={isConnected ? connectedKits : kits}
          items={isConnected ? connectedItems : items}
          sets={isConnected ? connectedSets : sets}
          status={activeCase.status}
        />;
      case 3:
        return <Step3Additional
          initialValues={formData}
          onClickNext={(formObj) => {
            setFormData({ ...formData, ...formObj });
            setStep(4);
          }}
          onClickBack={() => setStep(2)}
          onClose={handleClose}
          kits={isConnected ? connectedKits : kits}
          items={isConnected ? connectedItems : items}
          status={activeCase.status}
        />;
      case 4:
        return <Step4
          initialValues={formData}
          onClickNext={(formObj) => {
            setFormData({ ...formData, ...formObj });
            setStep(5);
          }}
          onClickBack={() => setStep(2)}
          onClose={handleClose}
          status={activeCase.status}
          hospitals={isConnected ? connectedHospitals : hospitals}
          userRole={userRole}
          userAddresses={userAddresses}
        />;
      case 5:
        return <Step5
          formData={formData}
          onSubmit={handleSubmit}
          onEditClick={setStep}
          onClickBack={() => setStep(4)}
          onClose={handleClose}
          surgeons={isConnected ? connectedSurgeons : surgeons}
          hospitals={isConnected ? connectedHospitals : hospitals}
          procedures={isConnected ? connectedProcedures : procedures}
          procedureOptions={isConnected ? connectedProcedures : procedureOptions}
          products={isConnected ? connectedProducts : products}
          kits={isConnected ? connectedKits : kits}
          items={isConnected ? connectedItems : items}
          loading={loading}
          status={activeCase.status}
          editingBy={editingBy}
          tenantColor={tenantColor}
          goToKit={goToKit}
        />;
      case 'kit':
        return <KitBOM
          kitId={selectedKit}
          onBack={onBack}
        />;
      default:
        return null;
    }
  };

  const getFormattedId = useCallback((caseId) => {
    if (!caseId) {
      return ''
    }
    const arr = caseId?.split('-');
    return arr?.length > 1 ? `${arr[0]}-${arr[1]}` : caseId;
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={mode === 'update' ? 'Edit Case' : 'Create Case'}
      subtitle={getFormattedId(activeCase.id)}
      fullWidth
      disableBackdropClick
      size='md'
    >
      <div className='case-modal__container'>
        {renderStep()}
      </div>
    </Modal>
  );
};

export default CaseModal;
