import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CaseIcon from '../../assets/icons/CaseIcon';
import ExportIcon from '../../assets/icons/DownloadIcon';
import FilterIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';

import CasesTableContainer from '../cases/CasesTableContainer';
import ActionButton from '../shared/ActionButton';

import { exportCases } from '../../actions/casesActions';

import routes from '../../constants/routes';
import { setAllocationStatuses } from '../../constants/enums';

import './reporting.scss';

const ReportingCasesTable = (props) => {
  const {
    cases, surgeons, hospitals, procedures, users,
    filter, filterOptions, onFilterChange, onRemoveFilter
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [exportLoading, setExportLoading] = useState(false);

  const getName = (field, id) => {
    let list = [];

    if (field === 'surgeonName') {
      list = surgeons;
    } else if (field === 'hospitalName') {
      list = hospitals;
    } else if (field === 'procedureName') {
      list = procedures;
    }

    const value = list?.find((item) => item.id === id);

    if (value) {
      return field === 'surgeonName' ? `${value?.firstName} ${value?.lastName}` : value.name;
    }

    return '';
  };

  const getUserName = useCallback((uid) => {
    const user = users?.find((u) => u.uid === uid);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [users]);

  const onExportClick = async () => {
    setExportLoading(true);
    const data = cases?.map((item) => ({
      id: item.id,
      surgeon: getName('surgeonName', item.surgeon),
      hospital: getName('hospitalName', item.hospital),
      procedure: getName('procedureName', item.procedure),
      createdBy: getUserName(item.createdBy),
      patientReference: item.patientReference || '',
      date: item.date,
      createdAt: item.createdAt,
      status: item.status,
    }));
    await dispatch(exportCases(data));
    setExportLoading(false);
  };

  return (
    <div className='cases-table__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <CaseIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Cases
          </div>
        </div>

        <ActionButton
          onClick={onExportClick}
          bgColor={setAllocationStatuses.SHIPPED.color}
          disabled={exportLoading}
          loading={exportLoading}
        >
          <ExportIcon color="#ffffff" />
          Export
        </ActionButton>
      </div>

      {filter && Object.keys(filter).some((key) => filter[key].length) && (
        <div className='filters-list__container'>
          <FilterIcon />

          {Object.keys(filter).map((key) => (
            <div key={key} className='d-flex'>
              {filter[key].map((value) => (
                <div key={`${key}-${value}`} className='filters-list__item'>
                  <div />
                  <div className='filter-list-item-value'>{getName(key, value)}</div>
                  <CloseIcon color='secondary' onClick={() => onRemoveFilter(key, value)} />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      <CasesTableContainer
        cases={cases}
        surgeons={surgeons}
        hospitals={hospitals}
        procedures={procedures}
        users={users}
        withoutCheck
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
        onSelectCase={(item) => history.push(`${routes.CASES}/${item.id}`)}
        checkboxes={false}
        pagination
        isReport
        filter={filter}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
      />
    </div>
  );
};

export default ReportingCasesTable;
