import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy } from 'lodash';

import { DriveBreadcrumbs, DriveBrowse, TreeViewContainer } from '../../../components/drive';
import Search from '../../../components/shared/Search';

import {
  subscribeToDrive,
  getFiles,
  searchFiles,
} from '../../../actions/driveActions';
import { getGroups } from '../../../actions/groupsActions';
import { getKits } from '../../../actions/kitsActions';

import { utils } from '../../../utils';

import './drive-page.scss';

const DrivePage = () => {
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant.currentTenant);
  const folders = useSelector((state) => [{ id: 'root', name: 'Drive' }, ...state.drive.folders]);
  const kits = useSelector((state) => state?.kits?.list?.filter((k) => !!k?.active));
  const isDriveLoaded = useSelector((state) => state.drive.isLoaded);

  const [activeFolder, setActiveFolder] = useState('root');
  const [foldersList, setFoldersList] = useState();
  const [activeFiles, setActiveFiles] = useState([]);
  const [fetchingFiles, setFetchingFiles] = useState(false);

  const [search, setSearch] = useState('');
  const [kitSearch, setKitSearch] = useState('');

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToDrive(true));

    onLoad().catch((err) => console.error(err));

    return () => {
      unsubscribe();
    };
  }, []);

  const onLoad = async () => {
    try {
      await Promise.all([
        dispatch(getGroups()),
        dispatch(getKits())
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isDriveLoaded) {
      const filteredFolders = [...folders].filter((folder) => folder.parent === 'root');
      setFoldersList(filteredFolders);
    }
  }, [isDriveLoaded]);

  useEffect(() => {
    if (activeFolder) {
      const filteredFolders = [...folders].filter((folder) => folder.parent === activeFolder);
      setFoldersList(filteredFolders);

      if (activeFolder !== 'root' && activeFolder !== 'kits') {
        fetchFolderFiles(activeFolder, true)
          .catch((err) => console.error(err));
      } else {
        setActiveFiles([]);
      }
    }
  }, [activeFolder]);

  useEffect(() => {
    if (activeFolder === 'kits' && !search) {
      setKitSearch('');
    }
  }, [search, activeFolder]);

  const fetchFolderFiles = async (folderId, withoutLoading) => {
    if (!withoutLoading) {
      setFetchingFiles(true);
    }

    try {
      const files = await dispatch(getFiles(folderId, true));
      setActiveFiles(sortBy(files, ['title']));
    } catch (err) {
      console.error(err);
    } finally {
      setFetchingFiles(false);
    }
  };

  const onFolderClick = (folderId) => {
    setSearch('');
    if (folderId !== activeFolder) {
      setActiveFolder(folderId);
      setActiveFiles([]);
    }
  };

  const onSearchClick = async () => {
     if (activeFolder === 'kits') {
       setKitSearch(search);
     } else {
       setFetchingFiles(true);
       setActiveFolder(null);

       const filteredFolders = [...folders].filter((folder) => folder.name.toLowerCase().includes(search.toLowerCase()));
       const filteredFiles = await dispatch(searchFiles(search));

       setFoldersList(filteredFolders);
       setActiveFiles(filteredFiles);
       setFetchingFiles(false);
     }
  };

  const tree = useMemo(() => {
    const children = utils.flatArrayToTree(folders);
    return {
      id: 'root',
      name: 'Drive',
      children: [
        { id: 'kits', name: 'Kits' },
        ...children
      ],
    };
  }, [folders]);

  const kitsList = useMemo(() => {
    if (!kitSearch) {
      return kits;
    }
    return kits?.filter((kit) => kit?.name?.toLowerCase()?.includes(kitSearch?.toLowerCase()) || kit?.kitId?.toLowerCase()?.includes(kitSearch?.toLowerCase()))
  }, [kits, kitSearch, search]);

  return (
    <div className='drive-page__container flex-1'>
      <span className='page-title'>Drive</span>
      <div className='drive-page__body flex-1'>
        <div className='drive-tree__container'>
          <TreeViewContainer
            data={tree}
            colorPrimary={tenant.colorPrimary}
            onClick={onFolderClick}
            active={activeFolder}
          />
        </div>
        <div className='drive-page__main'>
          <div>
            <DriveBreadcrumbs
              data={folders}
              colorPrimary={tenant.colorPrimary}
              onClick={onFolderClick}
              active={activeFolder}
            />
            <div className='drive-search__container'>
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSearchClick={onSearchClick}
                loading={fetchingFiles}
              />
            </div>
          </div>
          <DriveBrowse
            folders={foldersList}
            files={activeFiles}
            onFolderClick={onFolderClick}
            active={activeFolder}
            kits={kitsList}
          />
        </div>
      </div>
    </div>
  );
};

export default DrivePage;
