import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { routerReducer as routing } from 'react-router-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Sentry from '@sentry/react';

import App from './App';

import { AlertProvider } from './providers';

import reducers from './reducers';
import Firebase, { FirebaseContext } from './firebase';

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import sentryDSN from './config/sentry';

import './index.scss';

const combinedReducers = combineReducers({ ...reducers, routing });
const store = createStore(combinedReducers, composeWithDevTools(applyMiddleware(thunk)));

Sentry.init({
  environment: 'production',
  dsn: sentryDSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: ["!localhost"], // Exclude localhost for distributed tracing
  beforeSend(event) {
    if (window.location.hostname === "localhost") {
      return null; // Prevent sending events from localhost
    }
    return event; // Allow sending events for other hosts
  },
  replaysOnErrorSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0, // Always capture sessions where errors occur
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0, // Less tracing in prod
  replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={Firebase}>
      <AlertProvider>
        <BrowserRouter>
          <Route component={App} />
        </BrowserRouter>
      </AlertProvider>
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
