import React, { useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import IconButton from '@material-ui/core/IconButton';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import SplitModalUsageItem from './form/SplitModalUsageItem';
import NewUsageItem from './form/NewUsageItem';
import Modal from '../../shared/modal/Modal';
import Button from '../../shared/Button';
import Tabs from '../../shared/Tabs';

import { caseUsageTypes } from '../../../constants/enums';

import './modal.scss';

const SplitViewModal = (props) => {
  const {
    onClose,
    open,
    inventory = [],
    images = [],
    onApproveClick,
    onAddUsage,
    onUpdateUsage,
    onDelete,
    tenantColor,
    orderClosed,
    users = [],
    tags = [],
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [addState, toggleAddState] = useState(false);

  const onSubmit = (formObj) => {
    if (formObj.quantity && (formObj.ref || formObj.lot || formObj.gtin)) {
      onAddUsage(caseUsageTypes.implants, formObj);
      toggleAddState(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size='lg'
      fullWidth
    >
      <div className='split-view-modal__container'>
        <div className='split-view-modal__content'>
          <div>
            {images && images?.length ? (
              <TransformWrapper>
                {({ zoomIn, zoomOut, resetTransform }) => (
                  <>
                    <div className='split-view-modal__tools'>
                      <IconButton onClick={zoomIn} size='small'>
                        <ZoomInIcon fontSize='large' />
                      </IconButton>
                      <IconButton onClick={zoomOut} size='small'>
                        <ZoomOutIcon fontSize='large' />
                      </IconButton>
                      <IconButton onClick={resetTransform} size='small'>
                        <ClearIcon fontSize='large' />
                      </IconButton>
                    </div>
                    <TransformComponent>
                      <img src={images[activeTab]?.image?.downloadUrl} alt='usage' />
                    </TransformComponent>
                  </>
                )}
              </TransformWrapper>
            ) : (
              <div className='font-bold secondary' style={{ fontSize: 32 }}>
                Image preview here
              </div>
            )}
          </div>
          <div className='split-view-modal__inventory'>
            {addState ? (
              <NewUsageItem
                onSubmit={onSubmit}
              />
            ) : (
              inventory?.map((item) => (
                <SplitModalUsageItem
                  key={item.id}
                  item={item}
                  onApproveClick={onApproveClick}
                  onSave={onUpdateUsage}
                  onDelete={onDelete}
                  orderClosed={orderClosed}
                  tenantColor={tenantColor}
                  users={users}
                  tags={tags}
                />
              ))
            )}
          </div>
        </div>
        <div className='split-view-modal__actions'>
          <div>
            <Tabs
              tabs={images?.map((image, index) => ({ label: `Image ${index + 1}` }))}
              activeTab={activeTab}
              onTabChange={setActiveTab}
            />
          </div>
          <div>
            {!orderClosed ? (
              addState ? (
                <Button
                  type='outlined'
                  height={60}
                  width={64}
                  onClick={() => toggleAddState(!addState)}
                >
                  <CloseIcon style={{ color: tenantColor }} />
                </Button>
              ) : (
                <Button
                  type='icon'
                  onClick={() => toggleAddState(true)}
                >
                  <AddIcon style={{ color: '#000000' }} />
                </Button>
              )
            ) : (
              <div />
            )}
            <Button
              type='submit'
              text='Close'
              onClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SplitViewModal;
