import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';

import TargetModal from './TargetModal';
import ManufacturersModal from './ManufacturersModal';
import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import ModalSelectInput from '../shared/modal-select/ModalSelectInput';
import ModalSelect from '../shared/modal-select/ModalSelect';
import FileUpload from '../shared/FileUpload';

import { kitItemTypes, kitTypeOptions } from '../../constants/enums';
import routes from '../../constants/routes';

import '../../styles/shared/form.scss';

const KitForm = (props) => {
  const {
    mode = 'create',
    initialValues,
    buttonText,
    onSubmit,
    loading,
    products,
    onClose,
    kits = [],
    manufacturers = [],
    items = [],
    onParentSettingsClick,
  } = props;

  const history = useHistory();

  const { name } = initialValues || {};

  const [isModalOpen, toggleModal] = useState(false);
  const [targetModalOpen, toggleTargetModal] = useState(false);
  const [manufacturerModalOpen, toggleManufacturerModal] = useState(false);
  const [image, setImage] = useState(null);

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.kitId) {
      errors.kitId = 'Required';
    }
    if (kits?.map((k) => k.kitId)?.includes(values?.kitId?.trim()) && mode === 'create') {
      errors.kitId = 'Duplicate';
    }
    if (!values.type) {
      errors.type = 'Required';
    }

    return errors;
  };

  useEffect(() => {
    setImage(null);
  }, [initialValues?.id]);

  const getProductNames = (productIds) => {
    return productIds.map((id) => {
      const product = products.find((item) => item.id === id);
      return product ? product.name : '';
    })
  };

  const getManufacturerNames = (ids) => {
    if (!ids) {
      return [];
    }
    return ids?.map((id) => {
      const manufacturer = manufacturers.find((item) => item.id === id);
      return manufacturer ? manufacturer.name : '';
    })
  };

  const getBOMNames = (value) => {
    if (!value) {
      return '';
    }
    return orderBy(value, 'orderNumber')?.map((item) => item.name || '');
  };

  const getChildrenNames = (value) => {
    if (!value) {
      return '';
    }
    return value?.map((item) => {
      const arr = item?.type === kitItemTypes.KIT.value ? kits : items;
      const kitItem = arr?.find((i) => i.id === item.id);
      return kitItem?.code || kitItem?.kitId || '';
    });
  };

  return (
    <div className='form__container'>
      <Form
        onSubmit={(formObj) => onSubmit(formObj, image)}
        validate={validate}
        initialValues={initialValues}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value)
          }
        }}
      >
        {
          ({ handleSubmit, submitting, invalid, pristine, values }) => (
            <form onSubmit={handleSubmit} className='form__body'>
              <div className='form__subtitle'>
                { name ? name : 'Add Kit' }
              </div>
              { mode === 'update' && (
                <Field name='active'>
                  {({ input, meta }) => (
                    <Input
                      type='switch'
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      placeholder='Status'
                    />
                  )}
                </Field>
              )}

              <div className='m-b-sm'>
                <div className='field__title'>Name</div>
                <Field name='name'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='Name'
                      type='text'
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </div>

              <div className='m-b-sm'>
                <div className='field__title'>ID</div>
                <Field name='kitId'>
                  {({ input, meta }) => (
                    <Input
                      name={input.name}
                      value={input.value}
                      placeholder='ID'
                      type='text'
                      onChange={input.onChange}
                      error={meta.error && meta.error === 'Duplicate'}
                    />
                  )}
                </Field>
              </div>

              <div>
                <div className='field__title'>Type</div>
                <Field name='type'>
                  {({ input, meta }) => (
                    <Select
                      name={input.name}
                      value={input.value}
                      onChange={input.onChange}
                      options={Object.values(kitTypeOptions)}
                    />
                  )}
                </Field>

                <div className='m-t-lg'>
                  <Field name='allowQuantity'>
                    {({ input, meta }) => (
                      <Input
                        type='switch'
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        placeholder='Allow Quantity'
                      />
                    )}
                  </Field>
                </div>
              </div>

              { mode === 'update' && (
                <div className=''>
                  <div className='field__title'>Brands</div>
                  <Field name='products'>
                    {({ input, meta }) => (
                      <div className='m-b-lg'>
                        <ModalSelectInput
                          onClick={() => toggleModal(true)}
                          count={input.value.length}
                          label='Edit Brands'
                          values={getProductNames(input.value)}
                          icon='product'
                        />

                        <ModalSelect
                          title='Add Brands'
                          listTitle='Brands'
                          options={products}
                          onChange={input.onChange}
                          selected={input.value}
                          onClose={() => toggleModal(false)}
                          isOpen={isModalOpen}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}

              {!kits?.find((k) => !!k?.isParent && k?.children?.find((item) => item.id === initialValues?.id || item.kitId === initialValues?.id)) && (
                <div className=''>
                  <Field name='isParent'>
                    {({ input, meta }) => (
                      <Input
                        type='switch'
                        name={input.name}
                        value={input.value}
                        onChange={input.onChange}
                        placeholder='Parent Kit'
                      />
                    )}
                  </Field>
                </div>
              )}

              {mode === 'update' && (
                !!values?.isParent ? (
                  <div className=''>
                    <div className='field__title'>Parent Kits & Items</div>
                    <Field name='children'>
                      {({ input, meta }) => (
                        <div className='m-b-lg'>
                          <ModalSelectInput
                            onClick={() => onParentSettingsClick(initialValues?.id)}
                            count={input.value.length}
                            label='Edit Parent'
                            values={getChildrenNames(input.value)}
                            icon='parent'
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                ) : (
                  <div className=''>
                    <div className='field__title'>Bill of Material</div>
                    <Field name='sections'>
                      {({ input, meta }) => (
                        <div className='m-b-lg'>
                          <ModalSelectInput
                            onClick={() => history.push(`${routes.KITS_SETTINGS}/${initialValues?.id}`)}
                            count={input.value.length}
                            label='Add BOM'
                            values={getBOMNames(input.value)}
                            icon='bom'
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                )
              )}

              {mode === 'update' && (
                <div>
                  <div className='set-field__container'>
                    <div className='set-field-label'>Manufacturers</div>

                    <Field name='manufacturers'>
                      {({ input, meta }) => (
                        <div className='m-t-sm m-b-lg'>
                          <ModalSelectInput
                            onClick={() => toggleManufacturerModal(true)}
                            count={input.value.length}
                            label='Edit Manufacturers'
                            values={getManufacturerNames(input.value)}
                            icon='kit'
                          />

                          <ManufacturersModal
                            manufacturers={manufacturers}
                            title='Manufacturers'
                            onChange={input.onChange}
                            selected={input.value || []}
                            onClose={() => toggleManufacturerModal(false)}
                            isOpen={manufacturerModalOpen}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              )}

              {mode === 'update' && (
                <div className='m-b-lg'>
                  <div className='secondary m-b-sm'>Kit Logo (png or jpg)</div>
                  <Field name='logoName'>
                    {({ input, meta }) => (
                      <FileUpload
                        id='kit-logo'
                        file={image}
                        onUpload={(file) => {
                          setImage(file);
                          input.onChange(file.name);
                        }}
                        placeholder={input.value}
                        accept='image'
                      />
                    )}
                  </Field>
                </div>
              )}

              {mode === 'update' && (
                <div className=''>
                  <div className='field__title'>Kit Turns Target (set level)</div>
                  <Field name='target'>
                    {({ input, meta }) => (
                      <div className='m-b-lg'>
                        <ModalSelectInput
                          onClick={() => toggleTargetModal(true)}
                          label={input.value?.name ? 'Update' : 'Add'}
                          values={[input.value?.name || 'Add Turns Target']}
                          icon='none'
                        />

                        <TargetModal
                          open={targetModalOpen}
                          onClose={() => toggleTargetModal(false)}
                          initialValues={input.value}
                          onSubmit={input.onChange}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              )}

              {mode === 'update' && (
                <div className=''>
                  <div className='field__title'>Version Number</div>
                  <Field name='version'>
                    {({ input, meta }) => (
                      <Input
                        name={input.name}
                        value={input.value}
                        placeholder=''
                        type='text'
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </div>
              )}

              <div className='form__actions'>
                <Button
                  type='submit'
                  text={buttonText || 'Save Changes'}
                  disabled={invalid || loading || pristine}
                  loading={loading}
                  onClick={handleSubmit}
                />
                { mode === 'create' && (
                  <Button
                    type='cancel'
                    onClick={onClose}
                  />
                )}
              </div>
            </form>
          )}
      </Form>
    </div>
  );
};

export default KitForm;
