import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer'
import { sortBy } from 'lodash';
import { v4 as uuid } from 'uuid';

import WarningIcon from '@material-ui/icons/Error';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';

import ShippingIcon from '../../../assets/icons/ShippingIcon';
import EditIcon from '../../../assets/icons/EditIcon';
import ChecklistIcon from '../../../assets/icons/ChecklistIcon';
import ConsignmentIcon from '../../../assets/icons/ConsignedSetIcon';
import KitIcon from '../../../assets/icons/KitIcon';
import ItemIcon from '../../../assets/icons/ItemIcon';
import AddIcon from '../../../assets/icons/AddIcon';

import CaseSetsTable from './CaseSetsTable';
import CaseSetsModal from './CaseSetsModal';
import CaseConsumablesModal from './CaseConsumablesModal';
import ShipSetsModal from './ShipSetsModal';
import TransferSetsModal from './TransferSetsModal';
import DispatchModal from './DispatchModal';
import StatusActionButton from './ActionButton';
import ActionButton from '../../shared/ActionButton';
import CaseGuidanceModal from '../modals/CaseGuidanceModal';
import ShipNotesModal from '../modals/ShipNotesModal';
import DispatchPDF from './DispatchPDF';
import ChecklistsPDF from '../checklists/ChecklistsPDF';
import AddKitsModal from '../sets/AddKitsModal';
import AddItemsModal from '../sets/AddItemsModal';
import { ConfirmationModal } from '../../shared/modal';

import { useAlert, useLoading } from '../../../hooks';

import {
  updateSetsAllocation,
  bulkUpdateAllocations,
  getKitFiles,
  exportDispatchDocument,
  addChecklist,
  deleteChecklist,
  simpleUpdateCase,
  deleteSetsAllocation,
  addNewKits,
  addNewItems,
  getCase,
  createActivity,
  deleteParentKit, sendSetsShippedNotification,
} from '../../../actions/casesActions';
import { updateCaseAllocation } from '../../../actions/setsActions';
import { getSections } from '../../../actions/kitsActions';

import { fromMomentToTimestampDay } from '../../../utils/date';
import { downloadFile } from '../../../utils/utils';

import {
  setAllocationStatuses, caseChecklistStatuses, kitTypeOptions,
  itemTypeOptions, kitVariantTypes, setPositionOptions,
  billOfMaterialItemTypes, consumableStatuses, notificationTypes,
} from '../../../constants/enums';
import routes from '../../../constants/routes';
import userRoles from '../../../constants/userRoles';

import '../cases.scss';

const { AVAILABLE, ASSIGNED, DISABLED, SHIPPED, TRANSFERRED, RETURNED, USAGE, CONFIRMED } = setAllocationStatuses;

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
  }
}))(MenuItem);

const CaseSets = (props) => {
  const {
    additional,
    kits = [],
    items = [],
    parentKits = [],
    additionalParentKits = [],
    activeCase,
    datePassed,
    onEditClick = () => {
    },
    editable,
    caseChecklists,
    billOfMaterial,
    labels = [],
    editingBy,
    dispatchDocuments = [],
    tenant,
    hospitals = [],
    surgeons = [],
    procedures = [],
    procedureOptions = [],
    caseActivity = [],
    users = [],
    title,
    userRole,
    setsAllocation,
  } = props;

  const setsAllocationWithParents = useMemo(() => {
    if (!setsAllocation || !kits || !items) {
      return [];
    }
    let result = setsAllocation?.filter((allocation) => !allocation?.parentKitId)?.map((allocation) => {
      if (allocation.kit) {
        const kit = kits?.find((k) => k.id === allocation.kit);
        return { ...allocation, name: kit?.kitId || '', description: kit?.name || '' };
      } else {
        const item = items?.find((i) => i.id === allocation.itemId);
        return { ...allocation, name: item?.code || '', description: item?.checklistDescription || item?.description };
      }
    });

    result = sortBy(result, 'name');

    if (parentKits?.length && !additional) {
      parentKits?.forEach((kitId) => {
        const parent = kits?.find((k) => k.id === kitId);
        result.push({
          id: kitId,
          kit: kitId,
          name: parent?.kitId || '',
          description: parent?.name || '',
          isParent: true,
          quantity: 1
        });
        parent?.children?.forEach((c) => {
          const allocation = setsAllocation.find((a) => !a.additional && a.parentKitId === kitId && (a.kit === c.id || a.itemId === c.id));
          if (allocation) {
            if (allocation.kit) {
              const kit = kits?.find((k) => k.id === allocation.kit);
              result.push({ ...allocation, name: kit?.kitId || '', description: kit?.name || '' });
            } else {
              const item = items?.find((i) => i.id === allocation.itemId);
              result.push({
                ...allocation,
                name: item?.code || '',
                description: item?.checklistDescription || item?.description
              });
            }
          }
        });
      });
    }

    if (additionalParentKits?.length && !!additional) {
      additionalParentKits?.forEach((kitId) => {
        const parent = kits?.find((k) => k.id === kitId);
        result.push({
          id: kitId,
          kit: kitId,
          name: parent?.kitId || '',
          description: parent?.name || '',
          isParent: true,
          quantity: 1,
          additional: true
        });
        parent?.children?.forEach((c) => {
          const allocation = setsAllocation.find((a) => !!a.additional && a.parentKitId === kitId && (a.kit === c.id || a.itemId === c.id));
          if (allocation) {
            if (allocation.kit) {
              const kit = kits?.find((k) => k.id === allocation.kit);
              result.push({ ...allocation, name: kit?.kitId || '', description: kit?.name || '' });
            } else {
              const item = items?.find((i) => i.id === allocation.itemId);
              result.push({
                ...allocation,
                name: item?.code || '',
                description: item?.checklistDescription || item?.description
              });
            }
          }
        });
      });
    }

    return result;
  }, [setsAllocation, kits, items, parentKits, additionalParentKits]);

  const { id } = activeCase;
  const noEquipmentToShip = additional ? (activeCase?.noEquipmentToShip && !setsAllocation?.length) : activeCase.noEquipmentToShip;
  const kitVariant = additional ? kitVariantTypes.loan : activeCase.kitVariant;

  const dispatch = useDispatch();
  const history = useHistory();

  const hospital = useMemo(() => hospitals?.find((h) => h.id === activeCase?.hospital), [hospitals, activeCase]);
  const surgeon = useMemo(() => surgeons?.find((h) => h.id === activeCase?.surgeon), [surgeons, activeCase]);
  const procedure = useMemo(() => procedures?.find((p) => p.id === activeCase?.procedure), [procedures, activeCase]);
  const procedureOption = useMemo(() => procedureOptions?.find((p) => p.id === activeCase?.procedureOption), [procedureOptions, activeCase]);

  const [kitsModalOpen, toggleKitsModal] = useState(false);
  const [itemsModalOpen, toggleItemsModal] = useState(false);
  const [creating, setCreating] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    if (userRole === userRoles.SALES_REP.name) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getStatus = (set) => {
    if (!set.quarantined && !set.consigned && !set.caseAllocation) {
      if (billOfMaterial[set.kit]?.some((section) => section?.items?.some((item) => {
        const _item = items?.find((i) => i.id === item.id);
        if (_item && _item?.batchControl && (!set?.batchItems || set?.batchItems[item.id] === undefined || set?.batchItems[item.id]?.length < item?.quantity)) {
          return true;
        }
        if (_item && !_item?.batchControl && (set?.itemsQuantity && set?.itemsQuantity[item.id] !== undefined && set?.itemsQuantity[item.id] < item?.quantity)) {
          return true;
        }
        return false;
      }))) {
        return setPositionOptions.INCOMPLETE.value;
      } else {
        return setPositionOptions.AVAILABLE.value;
      }
    } else {
      return set.quarantined ? (
        setPositionOptions.QUARANTINED.value
      ) : (
        set.consigned ? (
          setPositionOptions.CONSIGNED.value
        ) : (
          // set.caseAllocation && set.caseAllocation !== activeCase.id ? setPositionOptions.ALLOCATED.value : setPositionOptions.AVAILABLE.value
          set.caseAllocation ? setPositionOptions.ALLOCATED.value : setPositionOptions.AVAILABLE.value
        )
      );
    }
  };

  const sets = useMemo(() => {
    return props?.sets?.filter((s) => activeCase?.kits?.includes(s.kit) || activeCase?.additionalKits?.includes(s.kit))?.map((s) => ({
      ...s,
      status: getStatus(s)
    })) || [];
  }, [props.sets, billOfMaterial, items]);

  const { loading, startLoading, stopLoading } = useLoading(false);
  const { showAlert } = useAlert();

  const [selectedAllocation, setSelectedAllocation] = useState(null);
  const [checkedSets, setCheckedSets] = useState([]);

  const [assignModalOpen, toggleAssignModal] = useState(false);
  const [consumablesModalOpen, toggleConsumablesModal] = useState(false);
  const [shipModalOpen, toggleShipModal] = useState(false);
  const [transferModalOpen, toggleTransferModal] = useState(null);
  const [dispatchModalOpen, toggleDispatchModal] = useState(false);
  const [deleteModalOpen, toggleDeleteModal] = useState(false);
  const [revertShippingModalOpen, toggleRevertShippingModal] = useState(false);

  const [documentsModalOpen, toggleDocumentsModal] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [documents, setDocuments] = useState([]);

  const [shipNotesModalOpen, toggleShipNotesModal] = useState(false);
  const [shippingNote, setShippingNote] = useState('');

  const [deleting, setDeleting] = useState(false);
  const [reverting, setReverting] = useState(false);

  const onSelectAllocation = (allocation) => {
    if (editingBy) {
      showAlert('warning', `${editingBy} is editing this case at the moment`);
    }

    if ([AVAILABLE.value, ASSIGNED.value, DISABLED.value].includes(allocation.status) && editable) {
      if (allocation.kit) {
        toggleAssignModal(true);
      } else {
        toggleConsumablesModal(true);
      }
      setSelectedAllocation(allocation);
    }
  };

  const handleCheckAll = () => {
    const list = setsAllocation.filter((item) => item.status !== DISABLED.value);
    const checked = checkedSets.length === list.length;
    const temp = [];

    if (!checked) {
      list.forEach((allocation) => {
        temp.push(allocation.id);
      });

      setCheckedSets(temp);
      return;
    }

    setCheckedSets(temp);
  };

  const handleCheck = (allocationId) => {
    const allocation = setsAllocation?.find((item) => item.id === allocationId);
    if (allocation.status === DISABLED.value) {
      return;
    }

    const checked = checkedSets.slice();

    if (checked.includes(allocationId)) {
      const index = checked.indexOf(allocationId);

      checked.splice(index, 1);
      setCheckedSets(checked);

      return;
    }

    checked.push(allocationId);
    setCheckedSets(checked);
  };

  const onAddSets = async (status, setIds, consumables) => {
    let data = { status };
    startLoading();

    if (status === ASSIGNED.value) {
      const kit = kits?.find((item) => item.id === selectedAllocation.kit);
      // await Promise.all(caseChecklists?.filter((item) => item.kitId === kit.kitId && !setIds.includes(item.id)).map((item) => dispatch(deleteChecklist(id, item.id))));
      if (setIds && setIds.length) {
        data.sets = setIds;
        await addChecklists(selectedAllocation, setIds, kit);
      } else {
        data.status = AVAILABLE.value;
        data.sets = [];
      }
    }

    if (status === DISABLED.value) {
      data.sets = [];
    }

    if (consumables && consumables.length) {
      data.consumables = consumables;
      data.status = ASSIGNED.value;
    }

    dispatch(updateSetsAllocation(id, selectedAllocation.id, data));
    setIds.forEach((setId) => dispatch(updateCaseAllocation(setId, activeCase.id)));
    setSelectedAllocation(null);
    // updateInstance();
    stopLoading();
  };

  const addChecklists = async (allocation, setIds, kit, caseId) => {
    let kitSections = billOfMaterial[allocation.kit];

    if (!kitSections) {
      const response = await dispatch(getSections(kit.id));
      kitSections = response || [];
    }

    if (kitSections && kitSections.length) {
      const filteredChecklists = caseId ? setIds : setIds.filter((setId) => !caseChecklists.find((item) => item.id === setId));
      const checklists = filteredChecklists.map((setId) => {
        const set = sets?.find((item) => item.id === setId);
        return {
          id: setId,
          additional: !!allocation.additional,
          consigned: kitVariant === kitVariantTypes.consignment,
          status: kitVariant === kitVariantTypes.consignment ? caseChecklistStatuses.COMPLETE.value : caseChecklistStatuses.INSPECT.value,
          kitId: kit.kitId || '',
          kitName: kit.name || '',
          setNumber: set.number || '',
          type: kitTypeOptions[kit?.type] ? kitTypeOptions[kit?.type].label : '',
          containerSize: set.containerSize || 1,
          quantity: allocation.quantity || 1,
          sections: kitSections?.map((section) => ({
            id: section.id || '',
            name: section.name || '',
            subtitle: section.subtitle || '',
            description: section.description || '',
            image: section.image || null,
            printOnNewPage: !!section.printOnNewPage,
            items: section.items?.map((item) => {
              if (item.type === billOfMaterialItemTypes.NOTES.value) {
                return item;
              } else {
                const _item = items?.find((i) => i.id === item.id);
                return {
                  id: item.id || '',
                  quantity: item.quantity || 0,
                  outbound: kitVariant === kitVariantTypes.consignment && set?.itemsQuantity?.[item.id] !== undefined ? set?.itemsQuantity?.[item.id] : (item.quantity || 0),
                  ref: item.ref || '',
                  description: _item?.checklistDescription || _item?.description || '',
                  code: _item?.code || '',
                  type: itemTypeOptions[_item?.type] ? itemTypeOptions[_item?.type].label : '',
                  image: _item?.image || null,
                  imageUrl: _item?.imageUrl || '',
                  hospitalIn: 0,
                  hospitalOut: 0,
                  returned: 0,
                };
              }
            })?.filter((item) => item.type === billOfMaterialItemTypes.NOTES.value || (item && item.id && item.code)),
          })),
        };
      });

      await Promise.all(checklists.map((checklist) => dispatch(addChecklist(caseId || id, checklist))));
    }
  };

  const onAddConsumables = async (consumables = []) => {
    startLoading();
    const data = { status: AVAILABLE.value };

    if (consumables && consumables.length) {
      data.status = ASSIGNED.value;
      data.consumables = consumables?.map((c) => ({
        id: uuid(),
        label: c,
        status: consumableStatuses.RETURNED.value,
      }));
    }

    dispatch(updateSetsAllocation(id, selectedAllocation.id, data));
    setSelectedAllocation(null);
    // updateInstance();
    stopLoading();
  };

  const onUnassignClick = async () => {
    const data = { status: AVAILABLE.value, consumables: [], sets: [] };
    await dispatch(updateSetsAllocation(id, selectedAllocation.id, data));

    if (selectedAllocation.kit) {
      const kit = kits?.find((k) => k.id === selectedAllocation.kit);
      const checklists = caseChecklists?.filter((c) => c.kitId === kit?.kitId && c.kitName === kit?.name && !!c.additional === !!selectedAllocation.additional);
      if (checklists?.length) {
        for (const checklist of checklists) {
          await dispatch(deleteChecklist(id, checklist.id));
        }
      }
    }

    selectedAllocation?.sets?.forEach((setId) => {
      dispatch(updateCaseAllocation(setId, null));
    });

    toggleConsumablesModal(false);
    toggleAssignModal(false);
    setSelectedAllocation(null);
    // updateInstance();
  };

  const onShipButtonClick = () => {
    if (!editable) {
      return;
    }

    const allocations = checkedSets.map((id) => setsAllocation?.find((item) => item.id === id));
    if ((!!checkedSets.length && allocations.every((allocation) => allocation.status === ASSIGNED.value)) || !checkedSets.length) {
      toggleShipModal(true);
    }
  };

  const onShipSets = async (formObj, sets) => {
    const data = sets.map((allocationId) => {
      const allocation = setsAllocation?.find((item) => item.id === allocationId);
      return {
        id: allocation.id,
        status: kitVariant === kitVariantTypes.consignment ? CONFIRMED.value : SHIPPED.value,
        trackingNumber: formObj.trackingNumber || '',
        shippingDate: fromMomentToTimestampDay(formObj.shippingDate ? formObj.shippingDate : moment()),
        shippingNote: formObj.shippingNote || '',
        freightCost: formObj.freightCost || 0,
      };
    });

    startLoading();
    await dispatch(bulkUpdateAllocations(id, data));

    const warningKits = activeCase?.warningKits ? activeCase?.warningKits?.filter((kit) => !sets?.includes(kit)) : [];
    dispatch(simpleUpdateCase(activeCase?.id, { warningKits }));
    dispatch(getCase(activeCase?.id));

    setCheckedSets([]);
    toggleShipModal(false);
    // updateInstance();
    stopLoading();

    // Send notifications
    dispatch(sendSetsShippedNotification(activeCase.id));
  };

  // Transfer sets

  const onTransferButtonClick = () => {
    if (!editable) {
      return;
    }

    const allocations = checkedSets.map((id) => setsAllocation?.find((item) => item.id === id));
    if (checkedSets.length === 1 && allocations.every((allocation) => allocation.status === SHIPPED.value || allocation.status === CONFIRMED.value)) {
      toggleTransferModal(allocations[0]);
    }
  };

  const onTransferSets = async (allocation, transferTo) => {
    const data = {
      status: TRANSFERRED.value,
      transferTo,
    };

    startLoading();
    dispatch(updateSetsAllocation(id, allocation.id, data));
    const kit = kits?.find((item) => item.id === allocation.kit);
    await addChecklists(allocation, allocation.sets, kit, transferTo);
    setCheckedSets([]);
    toggleTransferModal(null);
    // updateInstance();
    stopLoading();
  };

  const filteredSets = useMemo(() => {
    if (!sets || !selectedAllocation) {
      return [];
    }

    return sets.filter((set) => selectedAllocation.kit === set.kit);
  }, [sets, selectedAllocation]);

  const shippingButtonDisabled = () => {
    if (!editable) {
      return true;
    }

    const allocations = checkedSets.map((id) => setsAllocation?.find((item) => item.id === id));

    if (
      setsAllocation
        ?.filter((allocation) => allocation.status !== DISABLED.value)
        ?.every((allocation) => [SHIPPED.value, RETURNED.value, USAGE.value, CONFIRMED.value].includes(allocation.status))
    ) {
      return true;
    }

    if (
      setsAllocation
        ?.filter((allocation) => allocation.status !== DISABLED.value)
        ?.every((allocation) => allocation.status === AVAILABLE.value)
    ) {
      return true;
    }

    if (!checkedSets.length) {
      return false;
    }

    return (
      (!!checkedSets.length && allocations.some((allocation) => allocation?.status !== ASSIGNED.value)) || (
        !allocations.some((item) => (
          item.status === ASSIGNED.value && (
            !item?.sets?.length || item?.sets?.every((setId) => {
              const checklist = caseChecklists.find((item) => item.id === setId);
              return !checklist || (checklist && [caseChecklistStatuses.COMPLETE.value, caseChecklistStatuses.INCOMPLETE.value].includes(checklist?.status));
            })
          )
        ))
      )
    );
  }

  const returnButtonDisabled = () => {
    if (!editable) {
      return true;
    }

    if (!checkedSets.length) {
      return false;
    }

    const allocations = checkedSets.map((id) => setsAllocation.find((item) => item.id === id));

    return allocations
      .filter((allocation) => allocation?.status !== DISABLED.value)
      .some((allocation) => allocation?.status !== SHIPPED.value || allocation.status !== CONFIRMED.value);
  }

  const dispatchButtonDisabled = () => {
    if (userRole === userRoles.SALES_REP.name) {
      return true;
    }

    return (
      setsAllocation?.every((allocation) => !allocation.trackingNumber)
      && caseChecklists?.every((checklist) => ![caseChecklistStatuses.COMPLETE.value, caseChecklistStatuses.INCOMPLETE.value]?.includes(checklist?.status))
    )
  };

  // const usageButtonDisabled = () => {
  //   if (!editable || checkedSets.length !== 1) {
  //     return true;
  //   }
  //
  //   const allocation = setsAllocation?.find((item) => item.id === checkedSets[0]);
  //   return allocation?.status !== SHIPPED.value || !!allocation?.sets?.length || !allocation?.consumables?.length;
  // }

  const transferButtonDisabled = () => {
    if (!editable || checkedSets.length !== 1) {
      return true;
    }

    const allocation = setsAllocation?.find((item) => item.id === checkedSets[0]);

    return ![SHIPPED.value, CONFIRMED.value]?.includes(allocation?.status) || !allocation.sets || !allocation.sets.length;
  }

  const onDocumentsModalOpen = async (kitId) => {
    if (userRole === userRoles.SALES_REP.name) {
      return;
    }

    setFetching(true);
    toggleDocumentsModal(true);

    try {
      const files = kitId ? await dispatch(getKitFiles(kitId)) : [];
      setDocuments(files);
    } catch (err) {
      console.error(err);
    } finally {
      setFetching(false);
    }
  };

  const onNotesModalOpen = async (kit) => {
    setShippingNote(kit.shippingNote);
    toggleShipNotesModal(true);
  };

  const onCloseShipNotesModal = () => {
    toggleShipNotesModal(false);
    setTimeout(() => setShippingNote(''), 500);
  }

  const onExportClick = async (dispatchDoc, checklists, checklistsAdditional, documents = []) => {
    try {
      startLoading();
      if (dispatchDoc) {
        await downloadDispatchDoc();
      }
      if (checklists) {
       await downloadChecklistsDoc();
      }
      if (checklistsAdditional) {
        await downloadChecklistsDoc(true);
      }
      for (const doc of documents) {
        await dispatch(exportDispatchDocument(doc.path, doc.fileName));
      }
      toggleDispatchModal(false);
    } catch (e) {
      console.error(e);
    } finally {
      stopLoading();
    }
  }

  const downloadDispatchDoc = async () => {
    const blob = await pdf(
      <DispatchPDF
        tenant={tenant}
        activeCase={activeCase}
        hospital={hospital}
        surgeon={surgeon}
        procedure={procedure}
        procedureOption={procedureOption}
        deliveryAddress={activeCase?.deliveryAddress}
        setsAllocation={setsAllocation}
        kits={kits}
        items={items}
        sets={sets}
      />
    ).toBlob();
    downloadFile(blob, `${activeCase.id} – Dispatch.pdf`);
  };

  const downloadChecklistsDoc = async (withItems) => {
    const docItems = withItems ? setsAllocation?.filter((allocation) => !!allocation.itemId)?.map((allocation) => {
      const item = items?.find((i) => i.id === allocation.itemId);
      return {
        ...allocation,
        name: item?.code || '',
        description: item?.checklistDescription || item?.description
      };
    }) : [];
    const blob = await pdf(
      <ChecklistsPDF
        activeCase={activeCase}
        tenant={tenant}
        hospital={hospital}
        surgeon={surgeon}
        kits={kits}
        checklists={caseChecklists?.filter((checklist) => [caseChecklistStatuses.COMPLETE.value, caseChecklistStatuses.INCOMPLETE.value].includes(checklist.status))}
        caseActivity={caseActivity}
        users={users}
        sets={sets}
        withItems={withItems}
        items={docItems}
      />
    ).toBlob();
    downloadFile(blob, `${activeCase.id} – Checklists.pdf`);
  };

  const onDeleteClick = (allocation) => {
    setSelectedAllocation(allocation);
    toggleDeleteModal(true);
  };

  const onDeleteAllocation = async () => {
    if (selectedAllocation) {
      try {
        setDeleting(true);

        if (selectedAllocation?.isParent) {
          const children = setsAllocationWithParents?.filter((allocation) => allocation?.parentKitId === selectedAllocation.id && !!selectedAllocation.additional === !!allocation.additional);
          const kits = [...activeCase?.kits] || [];
          const items = [...activeCase?.items] || [];
          const additionalKits = [...activeCase?.additionalKits] || [];
          const additionalItems = [...activeCase?.additionalItems] || [];

          for (const allocation of children) {
            await dispatch(deleteSetsAllocation(activeCase, allocation));
          }
          await dispatch(deleteParentKit(activeCase, selectedAllocation.id, selectedAllocation.additional));

          if (selectedAllocation.additional) {
            const doc = {
              additionalKits: additionalKits?.filter((kitId) => !children?.map((c) => c.kit)?.includes(kitId)),
              additionalItems: additionalItems?.filter((itemId) => !children?.map((c) => c.itemId)?.includes(itemId)),
            };
            await dispatch(simpleUpdateCase(activeCase.id, doc));
          } else {
            const doc = {
              kits: kits?.filter((kitId) => !children?.map((c) => c.kit)?.includes(kitId)),
              items: items?.filter((itemId) => !children?.map((c) => c.itemId)?.includes(itemId)),
            };
            await dispatch(simpleUpdateCase(activeCase.id, doc));
          }
        } else {
          await dispatch(deleteSetsAllocation(activeCase, selectedAllocation));
        }

        await dispatch(getCase(activeCase?.id));
        toggleDeleteModal(false);
        setSelectedAllocation(null);
      } catch (err) {
        console.error(err);
      } finally {
        setDeleting(false);
      }
    }
  };

  const onAddNewKits = async (kits) => {
    try {
      handleClose();
      setCreating(true);
      await dispatch(addNewKits(activeCase, kits, additional));
      await dispatch(getCase(activeCase.id));
      toggleKitsModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setCreating(false);
    }
  };

  const onAddNewItems = async (items) => {
    try {
      handleClose();
      setCreating(true);
      await dispatch(addNewItems(activeCase, items, additional));
      await dispatch(getCase(activeCase.id));
      toggleKitsModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setCreating(false);
    }
  };

  const onRevertShippingClick = async (allocation) => {
    setSelectedAllocation(allocation);
    toggleRevertShippingModal(true);
  };

  const onRevertShipping = async () => {
    if (selectedAllocation) {
      try {
        setReverting(true);
        const doc = { status: ASSIGNED.value, shippingNote: '', shippingDate: null, trackingNumber: '', freightCost: null};
        await dispatch(updateSetsAllocation(activeCase.id, selectedAllocation.id, doc));

        let name = '';

        if (selectedAllocation.kit) {
          const kit = kits?.find((k) => k.id === selectedAllocation.kit);
          name = `${kit.kitId}, ${kit.name}`;
        }
        if (selectedAllocation.itemId) {
          const item = items?.find((i) => i.id === selectedAllocation.itemId);
          name = `${item.code}, ${item.description}`;
        }

        const activity = {
          type: notificationTypes.shippingReversed,
          title: 'Shipping Reversed',
          payload: `Shipping has been reversed for the following: ${name}`,
        };
        await dispatch(createActivity(activeCase.id, activity));
        toggleRevertShippingModal(false);
        setSelectedAllocation(null);
      } catch (err) {
        console.error(err);
      } finally {
        setReverting(false);
      }
    }
  };

  const showReturnButton = useMemo(() => (
    setsAllocation?.some((item) => item.status === SHIPPED.value || item.status === CONFIRMED.value) || datePassed
  ), [setsAllocation, datePassed]);

  const availableToShipSets = useMemo(() => {
    return (
      setsAllocation?.filter((item) => (
        item.status === ASSIGNED.value && (activeCase.kitVariant === kitVariantTypes.loan || !!item.additional === !!additional) && (
          !item?.sets?.length || item?.sets?.every((setId) => {
            const checklist = caseChecklists.find((item) => item.id === setId);
            return !checklist || (checklist && [caseChecklistStatuses.COMPLETE.value, caseChecklistStatuses.INCOMPLETE.value].includes(checklist.status));
          })
        )
      ))
    );
  }, [setsAllocation, caseChecklists, additional, activeCase.kitVariant]);

  const isWarning = useMemo(() => activeCase?.warningKits && !!activeCase?.warningKits?.length, [activeCase]);

  return (
    <div className='case-kits__container'>
      <div className='d-flex align-start space-between'>
        <div>
          <div className='d-flex'>
            {kitVariant && kitVariant === kitVariantTypes.consignment ? <ConsignmentIcon /> : <ShippingIcon />}
            <div className='font-size-bg font-bold m-l-md'>
              {title}
            </div>
          </div>
          {isWarning && (
            <div className='d-flex flex-end'>
              <span className='font-bold' style={{ color: setAllocationStatuses.QUARANTINED.color }}>
                Set Availability Warning
              </span>
              <WarningIcon style={{ color: setAllocationStatuses.QUARANTINED.color, marginLeft: 4 }} />
            </div>
          )}
        </div>

        <div className='case-sets-actions__container'>
          {editable && !(activeCase.kitVariant === kitVariantTypes.loan && !!activeCase.kitPreference && !additional) && (
            <>
              <ActionButton
                disabled={userRole === userRoles.SALES_REP.name}
                onClick={handleClick}
                bgColor={setAllocationStatuses.RETURNED.color}
              >
                <AddIcon color="#ffffff" />
                Add
              </ActionButton>
              <Menu
                id='long-menu'
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 200,
                    width: 200,
                  },
                  elevation: 3,
                }}
              >
                <StyledMenuItem
                  onClick={() => {
                    if (userRole !== userRoles.SALES_REP.name) {
                      toggleKitsModal(true);
                      handleClose();
                    }
                  }}
                >
                  <KitIcon />
                  <span className='m-l-md'>Add Kits</span>
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    if (userRole !== userRoles.SALES_REP.name) {
                      toggleItemsModal(true);
                      handleClose();
                    }
                  }}
                >
                  <ItemIcon />
                  <span className='m-l-md'>Add Items</span>
                </StyledMenuItem>
              </Menu>
            </>
          )}

          {editable && (
            <ActionButton
              disabled={userRole === userRoles.SALES_REP.name}
              onClick={onEditClick}
              bgColor={setAllocationStatuses.RETURNED.color}
            >
              <EditIcon color="#ffffff" />
              Edit
            </ActionButton>
          )}

          {!datePassed && !(activeCase.kitPreference && additional) && !(activeCase.kitVariant === kitVariantTypes.loan && additional) && (
            <StatusActionButton
              status={kitVariant === kitVariantTypes.consignment ? CONFIRMED.value : SHIPPED.value}
              onClick={onShipButtonClick}
              disabled={shippingButtonDisabled()}
              kitVariant={kitVariant}
            />
          )}

          {!noEquipmentToShip && (
            <>
              {showReturnButton && (
                <>
                  <StatusActionButton
                    status={kitVariant === kitVariantTypes.consignment ? USAGE.value : RETURNED.value}
                    onClick={() => {
                      if (userRole !== userRoles.SALES_REP.name) {
                        history.push(`${routes.CASES}/${id}${additional ? routes.RETURN_ADDITIONAL_SETS : routes.RETURN_SETS}`);
                      }
                    }}
                    disabled={returnButtonDisabled()}
                  />
                  {kitVariant !== kitVariantTypes.consignment && (
                    <StatusActionButton
                      status={setAllocationStatuses.TRANSFERRED.value}
                      onClick={onTransferButtonClick}
                      disabled={transferButtonDisabled()}
                    />
                  )}
                </>
              )}

              {kitVariant !== kitVariantTypes.consignment && !(activeCase.kitPreference && additional) && !(activeCase.kitVariant === kitVariantTypes.loan && additional) && (
                <StatusActionButton
                  status={setAllocationStatuses.DISPATCH.value}
                  onClick={() => {
                    if (userRole !== userRoles.SALES_REP.name) {
                      toggleDispatchModal(true);
                    }
                  }}
                  disabled={dispatchButtonDisabled()}
                />
              )}
            </>
          )}

          {caseChecklists && !!caseChecklists?.filter((item) => !item.consigned)?.length && kitVariant !== kitVariantTypes.consignment && !(activeCase.kitPreference && additional) && !(activeCase.kitVariant === kitVariantTypes.loan && additional) && (
            <ActionButton
              disabled={userRole === userRoles.SALES_REP.name}
              onClick={() => {
                if (userRole !== userRoles.SALES_REP.name) {
                  history.push(`${routes.CASES}/${id}${routes.CHECKLISTS}`);
                }
              }}
              bgColor={setAllocationStatuses.ASSIGNED.color}
            >
              <ChecklistIcon color="#ffffff" />
              Checklists
            </ActionButton>
          )}
        </div>
      </div>

      {noEquipmentToShip ? (
        <div className='font-size-bg secondary font-bold uppercase text-center p-lg m-lg'>
          No equipment to ship
        </div>
      ) : (
        <CaseSetsTable
          caseId={activeCase.id}
          setsAllocation={setsAllocationWithParents?.filter((allocation) => additional || !allocation.additional)}
          checkedSets={checkedSets}
          onSelectAllocation={onSelectAllocation}
          selectedAllocationId={selectedAllocation ? selectedAllocation.id : null}
          handleCheck={handleCheck}
          handleCheckAll={handleCheckAll}
          kits={kits}
          sets={sets}
          onButtonClick={onDocumentsModalOpen}
          onKitNotesClick={onNotesModalOpen}
          caseChecklists={caseChecklists}
          billOfMaterial={billOfMaterial}
          items={items}
          kitVariant={kitVariant}
          warningKits={activeCase?.warningKits || []}
          additional={additional}
          onDeleteClick={onDeleteClick}
          onRevertShipping={onRevertShippingClick}
          userRole={userRole}
        />
      )}

      <CaseSetsModal
        open={assignModalOpen}
        onClose={() => {
          toggleAssignModal(false);
          setSelectedAllocation(null)
        }}
        sets={selectedAllocation ? filteredSets : []}
        selected={selectedAllocation ? selectedAllocation.sets : []}
        caseId={id}
        onSubmit={onAddSets}
        loading={loading}
        kitId={selectedAllocation ? selectedAllocation.kit : null}
        kits={kits}
        status={selectedAllocation ? selectedAllocation.status : null}
        datePassed={datePassed}
        initialConsumables={selectedAllocation ? selectedAllocation.consumables : []}
        quantity={selectedAllocation ? selectedAllocation.quantity : 1}
        labels={labels}
        kitVariant={kitVariant}
        onUnassignClick={onUnassignClick}
        caseHospital={activeCase?.hospital}
        billOfMaterial={billOfMaterial}
        items={items}
        additional={additional}
      />

      <CaseConsumablesModal
        open={consumablesModalOpen}
        onClose={() => {
          toggleConsumablesModal(false);
          setSelectedAllocation(null)
        }}
        onSubmit={onAddConsumables}
        loading={loading}
        itemId={selectedAllocation ? selectedAllocation.itemId : null}
        items={items}
        initialConsumables={selectedAllocation ? selectedAllocation?.consumables?.map((c) => c.label) : []}
        quantity={selectedAllocation ? selectedAllocation.quantity : 1}
        onAnassignClick={onUnassignClick}
      />

      <ShipSetsModal
        open={shipModalOpen}
        onClose={() => toggleShipModal(false)}
        onSubmit={onShipSets}
        loading={loading}
        availableSets={availableToShipSets}
        checkedSets={checkedSets}
        kits={kits}
        sets={sets}
        items={items}
        kitVariant={kitVariant}
      />

      <TransferSetsModal
        open={!!transferModalOpen}
        onClose={() => toggleTransferModal(null)}
        allocation={transferModalOpen}
        onSubmit={onTransferSets}
        loading={loading}
        caseId={id}
      />

      <CaseGuidanceModal
        open={documentsModalOpen}
        onClose={() => toggleDocumentsModal(false)}
        files={documents}
        fetching={fetching}
        title='Kit Documents'
      />

      <ShipNotesModal
        open={shipNotesModalOpen}
        onClose={onCloseShipNotesModal}
        note={shippingNote}
        title={kitVariant === kitVariantTypes.consignment ? 'Notes' : 'Shipping Notes'}
      />

      <DispatchModal
        open={dispatchModalOpen}
        onClose={() => toggleDispatchModal(false)}
        onSubmit={onExportClick}
        loading={loading}
        dispatchDocuments={dispatchDocuments}
      />

      <ConfirmationModal
        open={deleteModalOpen}
        onClose={() => {
          toggleDeleteModal(false);
          setSelectedAllocation(null);
        }}
        onSubmit={onDeleteAllocation}
        title='Remove from Shipping List'
        text={selectedAllocation?.isParent ? 'Are you sure you want to remove all kits & items related to this parent kit from the shipping list?' : 'Are you sure you want to remove this from the shipping list?'}
        submitText='Remove'
        loading={deleting}
      />

      <ConfirmationModal
        open={revertShippingModalOpen}
        onClose={() => {
          toggleRevertShippingModal(false);
          setSelectedAllocation(null);
        }}
        onSubmit={onRevertShipping}
        title='Reverse shipping status?'
        text='Continue to set this line back to an Assigned status and permanently delete shipping information!'
        submitText='Confirm'
        loading={reverting}
      />

      <AddKitsModal
        open={kitsModalOpen}
        onClose={() => toggleKitsModal(false)}
        options={kits?.filter((k) => !activeCase.additionalKits?.includes(k.id))}
        onSubmit={onAddNewKits}
        loading={creating}
      />

      <AddItemsModal
        open={itemsModalOpen}
        onClose={() => toggleItemsModal(false)}
        options={items?.filter((i) => !activeCase.additionalItems?.includes(i.id))}
        onSubmit={onAddNewItems}
        loading={creating}
      />
    </div>
  );
};

export default CaseSets;
