import React from 'react';

import ParentKitIcon from '../../../assets/icons/ParentKitIcon';

const ParentKitBreadcrumbs = ({ kitId = '', kitName = '' }) => {
  return (
    <div className='drive-breadcrumbs__container'>
      <ParentKitIcon className='m-r-md' style={{ fontSize: '24px' }} />
      <div className='font-bold'>{kitName}</div>
      <div className='m-l-md secondary'>{kitId}</div>
    </div>
  );
};

export default ParentKitBreadcrumbs;
