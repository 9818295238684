import React, { useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { orderBy } from 'lodash';

import ViewCompactOutlinedIcon from '@material-ui/icons/ViewCompactOutlined';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import InventoryIcon from '../../assets/icons/CountIcon';
import AddIcon from '../../assets/icons/AddIcon';
import ExportIcon from '../../assets/icons/DownloadIcon';
import UsageIcon from '../../assets/icons/UsageIcon';
import GtinRefIcon from '../../assets/icons/GtinIcon';
import CheckIcon from '../../assets/icons/CheckIcon';
import ProformaIcon from '../../assets/icons/ProformaIcon';
import ItemIcon from '../../assets/icons/ItemIcon';

import CaseUsageModal from './modals/CaseUsageModal';
import UpdateUsageModal from './modals/UpdateUsageModal';
import UpdateImageModal from './modals/UpdateImageModal';
import CloseOrderModal from './modals/CloseOrderModal';
import SplitViewModal from './modals/SplitViewModal';
import ExportUsageModal, { EXPORT_TYPES } from './modals/ExportUsageModal';
import { CountInventoryTable, CountEquipmentTable } from '../counts';
import Tabs from '../shared/Tabs';
import ActionButton from '../shared/ActionButton';
import Modal, { ConfirmationModal } from '../shared/modal';
import AddItemsModal from './sets/AddItemsModal';
import AddUsageManualModal from './modals/AddUsageManualModal';

import {
  addUsageImage,
  addUsageImplants,
  updateUsageImplants,
  updateUsageImage,
  approveUsage,
  closeOrder,
  exportUsage,
  submitUsage,
  deleteUsage,
  createProformaList, getCase,
} from '../../actions/casesActions';

import { useLoading } from '../../hooks';

import { caseUsageTypes, setAllocationStatuses, caseStatusOptions, setAllocationTypes } from '../../constants/enums';

const tabs = [
  { label: 'Implants' },
  { label: 'Images' },
];

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
  },
}))(MenuItem);

const { USAGE, RETURNED } = setAllocationStatuses;

const getValue = (scan, label) => {
  const value = scan.values
    ? scan.values.find((item) => item.label.toLowerCase() === label)
    : null;
  return value ? value.value : '';
}

const CaseUsageContainer = (props) => {
  const {
    activeCase,
    caseId,
    status,
    scans,
    gtinReference,
    users,
    orderClosed,
    editable,
    checklists = [],
    setsAllocation = [],
    items = [],
    kits = [],
    connectedTenantId,
    proformaValue,
    proformaSent,
    proformaGenerated,
    goToProforma,
    hospitals,
    surgeons,
    tags = [],
  } = props;

  const csvLink = useRef();
  const csvUnleashedLink = useRef();

  const dispatch = useDispatch();
  const tenantColor = useSelector((state) => state?.tenant?.currentTenant?.colorPrimary);

  const { loading, startLoading, stopLoading } = useLoading(false);

  const [selectedScan, setSelectedScan] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [gtinRefActive, toggleGtinRef] = useState(false);

  const [closingOrder, toggleClosing] = useState(false);
  const [exportLoading, toggleExportLoading] = useState(false);
  const [proformaLoading, toggleProformaLoading] = useState(false);
  const [itemsLoading, toggleItemsLoading] = useState(false);

  const [isModalOpen, toggleModal] = useState(false);
  const [isOrderModalOpen, toggleOrderModal] = useState(false);
  const [isSplitModalOpen, toggleSplitModal] = useState(false);
  const [isProformaModalOpen, toggleProformaModal] = useState(false);
  const [isExportModalOpen, toggleExportModal] = useState(false);
  const [isItemsModalOpen, toggleItemsModal] = useState(false);
  const [isManualModalOpen, toggleManualModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const inventory = useMemo(() => scans.filter((scan) => scan.type === caseUsageTypes.implants), [scans, activeTab]);
  const images = useMemo(() => scans.filter((scan) => scan.type === caseUsageTypes.images), [scans, activeTab]);

  const consumables = useMemo(() => {
    let arr = [];
    if (setsAllocation && setsAllocation.length && items && items.length) {
      arr = setsAllocation
        ?.filter((allocation) => allocation?.consumables?.length)
        ?.map((allocation) => {
          const item = allocation.kit ? kits?.find((k) => k.id === allocation.kit) : items?.find((i) => i.id === allocation.itemId);
          return ({
            id: item?.id || '',
            code: item?.code || item?.kitId || '',
            description: item?.description || item?.name || '',
            checklistDescription: item?.checklistDescription || '',
            imageUrl: item?.image?.downloadUrl || item?.imageUrl || '',
            allocationType: setAllocationTypes.ITEM,
            allocationStatus: allocation?.status,
            allocationId: allocation?.id,
            quantity: allocation?.consumables?.length || 1,
            type: item?.type || '',
            consumables: allocation?.consumables?.map((cons) => {
              if (cons?.id) {
                return {
                  id: cons.id || '',
                  label: cons.label || '',
                  status: cons.status || RETURNED.value,
                  qty: 0,
                }
              } else {
                return {
                  label: cons,
                  status: allocation?.usageConsumables?.includes(cons) ? USAGE.value : RETURNED.value,
                  qty: 0,
                };
              }
            }),
          });
        });
    }
    return arr;
  }, [setsAllocation, items]);

  const list = useMemo(() => {
    const result = [];

    setsAllocation?.forEach((allocation) => {
      if (allocation.sets && !!allocation.sets.length) {
        allocation.sets.forEach((setId) => {
          const checklist = checklists?.find((c) => c.id === setId);
          if (checklist) {
            result?.push({
              ...checklist,
              allocationId: allocation.id,
              allocationType: setAllocationTypes.SET,
              allocationStatus: allocation.status,
            });
          }
        });
      }
      if (allocation.consumables && allocation.consumables.length) {
        const consumable = consumables?.find((i) => i.id === allocation.itemId || i.id === allocation.kit);
        if (!!consumable) {
          result?.push(consumable);
        }
      }
    });

    return result;
  }, [checklists, consumables]);

  const renderTable = () => {
    switch (activeTab) {
      case 0:
        return (
          <CountInventoryTable
            scans={inventory}
            onSelect={(scan) => {
              if (!orderClosed && !proformaSent && !scan.approved) {
                setSelectedScan(scan);
              }
            }}
            selected={selectedScan}
            gtinRefActive={gtinRefActive}
            gtinReference={gtinReference}
            users={users}
            onApproveClick={onApproveUsage}
            orderClosed={orderClosed}
            proformaSent={proformaSent}
            proformaGenerated={proformaGenerated}
            isSubmitted={status === caseStatusOptions.completed}
            tags={tags}
          />
        );
      case 1:
        return (
          <CountEquipmentTable
            scans={images}
            onSelect={(image) => {
              if (!orderClosed) {
                setSelectedImage(image);
              }
            }}
            selected={selectedScan}
            users={users}
          />
        );
      default:
        return null;
    }
  };

  const onAddUsage = async (usage = []) => {
    startLoading();

    try {
      await Promise.all(usage?.map((item) => dispatch(addUsageImplants(caseId, item, connectedTenantId))));
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
      toggleModal(false);
    }
  };

  const onAddUsageManual = async (type, formObj, image) => {
    if (!editable) {
      return;
    }

    startLoading();

    try {
      if (type === caseUsageTypes.images) {
        await dispatch(addUsageImage(caseId, formObj, image, connectedTenantId));
      } else {
        await dispatch(addUsageImplants(caseId, formObj, connectedTenantId));
      }
      toggleManualModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const onAddUsageFromItems = async (usageItems) => {
    try {
      toggleItemsLoading(true);

      for (const item of usageItems) {
        const _item = items?.find((i) => i.id === item.id);
        if (_item) {
          const usage = {
            ref: _item?.code || '',
            desc: _item?.checklistDescription || _item?.description || '',
            scanType: 'USAGE',
            quantity: item?.quantity,
            lot: ''
          }
          await dispatch(addUsageImplants(caseId, usage, connectedTenantId));
        }
      }

      toggleItemsModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      toggleItemsLoading(false);
    }
  };

  const onUpdateUsage = async (formObj) => {
    if (!editable) {
      return;
    }

    startLoading();

    try {
      await dispatch(updateUsageImplants(caseId, formObj, connectedTenantId));
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
      setSelectedScan(null);
    }
  };

  const onUpdateImage = async (formObj) => {
    if (!editable) {
      return;
    }

    startLoading();

    try {
      await dispatch(updateUsageImage(caseId, formObj, connectedTenantId));
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
      setSelectedImage(null);
    }
  };

  const onApproveUsage = async (usageId, value) => {
    if (!editable) {
      return;
    }

    if (!orderClosed) {
      try {
        await dispatch(approveUsage(caseId, usageId, value, connectedTenantId));
      } catch (err) {
        console.error(err);
      }
    }
  };

  const onSubmitUsage = async () => {
    if (!editable) {
      return;
    }

    try {
      await dispatch(submitUsage(caseId, connectedTenantId));
      await dispatch(getCase(caseId));
    } catch (err) {
      console.error(err);
    }
  };

  const onCloseOrder = async (reference, files, finalOrderValue) => {
    if (!editable) {
      return;
    }

    toggleClosing(true);

    try {
      await dispatch(closeOrder(caseId, reference, files, finalOrderValue, connectedTenantId));
      await dispatch(getCase(caseId));
      toggleOrderModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      toggleClosing(false);
    }
  };

  const onExport = async (exportType) => {
    try {
      toggleExportLoading(true);

      if (exportType === EXPORT_TYPES.DEFAULT_CSV) {
        csvLink.current?.link?.click();
      } else if (exportType === EXPORT_TYPES.UNLEASHED_CSV) {
        csvUnleashedLink.current?.link?.click();
      } else {
        await dispatch(exportUsage(caseId, connectedTenantId));
      }

      toggleExportModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      toggleExportLoading(false);
    }
  };

  const onDelete = async (id) => {
    try {
      await dispatch(deleteUsage(caseId, id, connectedTenantId));
    } catch (err) {
      console.error(err);
    }
  };

  const generateProformaInvoice = async () => {
    try {
      toggleProformaLoading(true);
      dispatch(createProformaList(caseId, inventory));
      goToProforma();
    } catch (err) {
      console.error(err);
    } finally {
      toggleProformaLoading(false);
    }
  };

  const onProformaClick = () => {
    if (proformaGenerated) {
      goToProforma();
    } else {
      toggleProformaModal(true);
    }
  };

  const csvDefault = useMemo(() => {
    const headers = [
      'REF',
      'Description',
      'LOT',
      'QTY',
      'Value',
      'Rebate',
      'Patient Reference',
      'Surgeon',
      'Hospital',
      'Case Date',
      'Usage Status',
      'PO Number',
      'Case ID',
      'Description (image)',
    ];
    const hospital = hospitals?.find((h) => h.id === activeCase?.hospital);
    const surgeon = surgeons?.find((s) => s.id === activeCase?.surgeon);
    const implants = orderBy(scans?.filter((usage) => usage.type === caseUsageTypes.implants)?.map((u) => ({
      ...u,
      refValue: getValue(u, 'ref'),
    })), 'refValue');
    const image = scans?.find((usage) => usage.type === caseUsageTypes.images && !!getValue(usage, 'desc'));
    const descriptionImage = image ? getValue(image, 'desc') : '';

    const rows = implants?.map((usage) => {
      const refValue = getValue(usage, 'ref');
      const descValue = getValue(usage, 'desc');
      const lotValue = getValue(usage, 'lot');
      const quantity = usage?.quantity || '';
      const item = items?.find((i) => i.code === refValue);
      const description = item && item.description ? item.description?.replaceAll(',', ' ') : descValue?.replaceAll(',', ' ');
      const price = item && item.value ?
        `$${Number(item.value).toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`?.replaceAll(',', '')
        : '';
      const rebateCode = item && item.rebateCode ? item.rebateCode : '';
      const patientRef = activeCase.patientReference || '';
      const surgeonName = surgeon ? `${surgeon.firstName} ${surgeon.lastName}` : '';
      const hospitalName = hospital ? hospital.name : '';
      const caseDate = activeCase.date ? moment(activeCase.date)?.format('DD/MM/YYYY') : '';
      const usageStatus = usage.approved ? 'Approved' : 'Not Approved';
      const poNumber = activeCase.orderReference || '';
      const arr = activeCase.id.split('-');
      const formattedId = arr?.length > 1 ? `${arr[0]}-${arr[1]}` : activeCase.id;

      return [
        refValue,
        description,
        lotValue,
        quantity,
        price,
        rebateCode,
        patientRef,
        surgeonName,
        hospitalName,
        caseDate,
        usageStatus,
        poNumber,
        formattedId,
        descriptionImage,
      ];
    });

    return [headers, ...rows];
  }, [activeCase, hospitals, surgeons, scans]);

  const csvUnleashed = useMemo(() => {
    const headers = [
      '*Order Number',
      '*Customer Code',
      'Tax Code',
      'Tax Rate',
      'Exchange Rate',
      'Customer Reference',
      'Comments',
      'Order Date (DD/MM/YYYY)',
      'Quote Expiry Date (DD/MM/YYYY)',
      'Required Date (DD/MM/YYYY)',
      'Warehouse Code',
      'Delivery Contact',
      'Delivery Name',
      'Delivery Street Address',
      'Delivery Street Address 2',
      'Delivery Suburb',
      'Delivery City',
      'Delivery Region',
      'Delivery Post Code',
      'Delivery Country',
      'Delivery Instruction',
      'Discount (%)',
      'Sales Order Group',
      'Sales Person Email',
      '*Line Number',
      '*Product Code',
      '*Order Quantity',
      'Line Discount (%)',
      'Unit Price',
      'Line Comments',
    ];
    const implants = orderBy(scans?.filter((usage) => usage.type === caseUsageTypes.implants)?.map((u) => ({
      ...u,
      refValue: getValue(u, 'ref'),
    })), 'refValue');

    const rows = implants?.map((usage, index) => {
      const arr = activeCase.id.split('-');
      const formattedId = arr?.length > 1 ? `${arr[0]}-${arr[1]}` : activeCase.id;
      const hospital = hospitals?.find((h) => h.id === activeCase?.hospital);
      const customerCode = hospital?.accountNumber || '';
      const customerReference = activeCase?.patientReference || '';
      const orderDate = moment().format('DD/MM/YYYY');
      const requiredDate = moment().add(30, 'days').format('DD/MM/YYYY');
      const refValue = getValue(usage, 'ref');

      return [
        index === 0 ? formattedId : '',
        index === 0 ? customerCode : '',
        '',
        '',
        '',
        index === 0 ? customerReference : '',
        '',
        index === 0 ? orderDate : '',
        '',
        index === 0 ? requiredDate : '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        index + 1,
        refValue,
        usage?.quantity || 1,
        '',
        '',
        '',
      ];
    });

    return [headers, ...rows];
  }, [activeCase, hospitals, scans]);

  return (
    <div className="count-scans__container">
      <CSVLink
        data={csvDefault}
        filename={caseId}
        className="hidden"
        ref={csvLink}
        target="_blank"
        enclosingCharacter={``}
      />
      <CSVLink
        data={csvUnleashed}
        filename={`${caseId}_unleashed`}
        className="hidden"
        ref={csvUnleashedLink}
        target="_blank"
        enclosingCharacter={``}
      />

      <div className="d-flex m-b-lg space-between align-start">
        <div className="d-flex">
          <InventoryIcon />
          <div className="font-size-bg font-bold m-l-md">
            Usage
          </div>
          {/*{ !!orderClosed && (*/}
          {/*  <div*/}
          {/*    className='font-size-sm font-bold m-l-md m-t-sm'*/}
          {/*    style={{ color: setAllocationStatuses.AVAILABLE.color }}*/}
          {/*  >*/}
          {/*    {`Closed${orderReference ? ` – ${orderReference}` : ''}`}*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>

        <div className="count-scans-actions__container">
          <ActionButton
            onClick={() => toggleGtinRef(!gtinRefActive)}
            bgColor={gtinRefActive ? setAllocationStatuses.RETURNED.color : '#DDDDDD'}
          >
            <GtinRefIcon color="#ffffff" />
            GTIN Ref
          </ActionButton>

          {(!!inventory?.length || !!images?.length) && status === caseStatusOptions.completed && (
            <ActionButton
              onClick={() => toggleSplitModal(true)}
              bgColor={setAllocationStatuses.TRANSFERRED.color}
            >
              <ViewCompactOutlinedIcon style={{ fill: '#ffffff' }} />
              Split View
            </ActionButton>
          )}

          <ActionButton
            onClick={handleClick}
            bgColor={setAllocationStatuses.RETURNED.color}
            disabled={loading || orderClosed || exportLoading || !editable || proformaSent}
            loading={loading}
          >
            <AddIcon color="#ffffff" />
            Add
          </ActionButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 3,
            }}
          >
            <StyledMenuItem
              onClick={() => {
                toggleItemsModal(true);
                handleClose();
              }}
            >
              <ItemIcon />
              <span className="m-l-md">Add Items (from master)</span>
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                toggleModal(true);
                handleClose();
              }}
            >
              <ItemIcon />
              <span className="m-l-md">Add Items (from shipped)</span>
            </StyledMenuItem>
          </Menu>

          {status === caseStatusOptions.completed && (
            <ActionButton
              onClick={() => toggleExportModal(true)}
              bgColor={setAllocationStatuses.SHIPPED.color}
              disabled={exportLoading || loading || inventory?.some((item) => !item.approved)}
              loading={exportLoading}
            >
              <ExportIcon color="#ffffff" />
              Export
            </ActionButton>
          )}

          {status === caseStatusOptions.completed && (
            <ActionButton
              onClick={onProformaClick}
              bgColor={setAllocationStatuses.TRANSFERRED.color}
              loading={proformaLoading}
              disabled={proformaLoading || !editable || inventory?.some((item) => !item.approved)}
            >
              <ProformaIcon color="#ffffff" />
              Proforma
            </ActionButton>
          )}

          {status !== caseStatusOptions.completed && (
            <ActionButton
              onClick={onSubmitUsage}
              bgColor={setAllocationStatuses.RETURNED.color}
              disabled={loading || exportLoading || !editable}
            >
              <UsageIcon color="#ffffff" />
              Submit
            </ActionButton>
          )}

          {status === caseStatusOptions.completed && (proformaSent || proformaGenerated) && (
            <ActionButton
              onClick={() => toggleOrderModal(true)}
              bgColor={setAllocationStatuses.RETURNED.color}
              disabled={loading || inventory.some((item) => !item.approved) || orderClosed || exportLoading || !editable}
            >
              <CheckIcon color="#ffffff" />
              Close
            </ActionButton>
          )}
        </div>
      </div>

      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      >
        <div className="m-t-lg">
          {renderTable()}
        </div>
      </Tabs>

      <CaseUsageModal
        open={isModalOpen}
        onClose={() => toggleModal(false)}
        onManualSubmit={onAddUsageManual}
        onSubmit={onAddUsage}
        loading={loading}
        defaultList={list}
        tenantColor={tenantColor}
        tags={tags}
      />

      <UpdateUsageModal
        open={!!selectedScan}
        onClose={() => setSelectedScan(null)}
        usage={selectedScan}
        onSubmit={onUpdateUsage}
        onDelete={onDelete}
        loading={loading}
        tags={tags}
      />

      <UpdateImageModal
        open={!!selectedImage}
        onClose={() => setSelectedImage(null)}
        usage={selectedImage}
        onSubmit={onUpdateImage}
        onDelete={onDelete}
        loading={loading}
      />

      <CloseOrderModal
        open={isOrderModalOpen}
        onClose={() => toggleOrderModal(false)}
        onSubmit={onCloseOrder}
        loading={closingOrder}
        tenantColor={tenantColor}
        proformaValue={proformaValue}
      />

      <SplitViewModal
        open={isSplitModalOpen}
        onClose={() => toggleSplitModal(false)}
        inventory={inventory}
        images={images}
        onApproveClick={onApproveUsage}
        onAddUsage={onAddUsageManual}
        onUpdateUsage={onUpdateUsage}
        onDelete={onDelete}
        tenantColor={tenantColor}
        orderClosed={orderClosed}
        users={users}
        tags={tags}
      />

      <ConfirmationModal
        open={isProformaModalOpen}
        onClose={() => toggleProformaModal(false)}
        onSubmit={generateProformaInvoice}
        title="Generate Proforma"
        text="You are about to use this current usage data to generate a proforma invoice. Any additional updates to usage will not be transferred to the Proforma."
        submitText="Continue"
        loading={proformaLoading}
      />

      <ExportUsageModal
        open={isExportModalOpen}
        onClose={() => toggleExportModal(false)}
        onSubmit={onExport}
        loading={exportLoading}
      />

      <AddItemsModal
        open={isItemsModalOpen}
        onClose={() => toggleItemsModal(false)}
        options={items}
        onSubmit={onAddUsageFromItems}
        loading={itemsLoading}
        title="Add Usage"
        withManual
        onManualClick={() => {
          toggleItemsModal(false);
          toggleManualModal(true);
        }}
      />

      <Modal
        open={isManualModalOpen}
        onClose={() => toggleManualModal(false)}
        title='Add Usage'
        fullWidth
      >
        <AddUsageManualModal
          onClose={() => toggleManualModal(false)}
          onSubmit={onAddUsageManual}
          loading={loading}
          tags={tags}
        />
      </Modal>
    </div>
  );
};

export default CaseUsageContainer;
