import React, { useState, useMemo, useEffect } from 'react';

import Modal from '../shared/modal';
import Button from '../shared/Button';
import Input from '../shared/Input';
import Select from '../shared/Select';
import Checkbox from '../shared/Checkbox';

import { itemTypeOptions, setPositionOptions } from '../../constants/enums';

const QuarantineModal = (props) => {
  const {
    open,
    onClose,
    onSubmit,
    loading,
    items = [],
    itemsList = [],
    initialValues,
  } = props;

  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [note, setNote] = useState(initialValues?.quarantineNote || '');
  const [selected, setSelected] = useState(initialValues?.quarantineItems?.length ? initialValues.quarantineItems : []);

  useEffect(() => {
    if (open) {
      if (initialValues?.quarantineNote) {
        setNote(initialValues?.quarantineNote);
      }
      if (initialValues?.quarantineItems?.length) {
        setSelected(initialValues?.quarantineItems);
      }
    }
  }, [open, initialValues]);

  const handleCheck = (itemId) => {
    let temp = selected?.slice();

    if (selected?.includes(itemId)) {
      temp = selected?.filter((i) => i !== itemId)
    } else {
      temp.push(itemId);
    }

    setSelected(temp);
  };

  const handleSubmit = () => {
    onSubmit(selected, note);
    setNote('');
    setSelected([]);
  };

  const list = useMemo(() => {
    const formattedItems = items?.map((item) => {
      const _item = itemsList?.find((i) => i.id === item.id);
      return _item ? { ...item, ..._item } : item;
    });
    return formattedItems?.filter((item) => (
      (!search || item?.code?.toLowerCase()?.includes(search?.toLowerCase())) && (!type || item?.type === type)
    ));
  }, [items, itemsList, search, type]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Quarantine Set'
      fullWidth
      size='md'
    >
      <div className='quarantine-modal__container'>
        <div className='d-flex align-start'>
          <div className='m-r-md flex-1'>
            <Input
              type='search'
              placeholder='Search'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          <div className='m-l-md flex-1'>
            <Select
              value={type}
              onChange={setType}
              options={[{ label: 'All', value: '' }, ...Object.values(itemTypeOptions)]}
            />
          </div>
        </div>

        <div className='m-b-lg'>
          <div className='secondary m-b-sm'>Select any items related to quarantine</div>
          <div className='quarantine-items__container'>
            {list?.map((item) => (
              <div key={item.id} className='d-flex'>
                <div style={{ minWidth: 200 }}>
                  <Checkbox
                    fontSize='small'
                    input={{
                      checked: !!selected.find((i) => i === item.id),
                      onClick: () => handleCheck(item.id)
                    }}
                    label={item.code}
                  />
                </div>
                <div className='flex-1'>{item.description || ''}</div>
                <div className='text-right'>{itemTypeOptions[item.type]?.label}</div>
              </div>
            ))}
          </div>
        </div>

        <div className='m-t-sm'>
          <div className='secondary m-b-md'>Quarantine note</div>
          <Input
            value={note}
            onChange={(e) => setNote(e.target.value)}
            type='textarea'
          />
        </div>

        <div className='form__actions p-t-lg'>
          <Button
            type='submit'
            text={initialValues?.quarantineNote || initialValues?.quarantineItems?.length ? 'Save' : 'Quarantine'}
            onClick={handleSubmit}
            disabled={loading}
            loading={loading}
            background={initialValues?.quarantineNote || initialValues?.quarantineItems?.length ? setPositionOptions.CONSIGNED.color : setPositionOptions.QUARANTINED.color}
          />
        </div>
      </div>
    </Modal>
  );
};

export default QuarantineModal;
