import React, { useCallback } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

import { caseStatusOptions } from '../../constants/enums';

import './cases.scss';

const getClassName = (status) => {
  switch (status) {
    case caseStatusOptions.request:
      return 'calendar-view-event__request';
    case caseStatusOptions.booked:
      return 'calendar-view-event__booked';
    case caseStatusOptions.overdue:
      return 'calendar-view-event__overdue';
    case caseStatusOptions.completed:
      return 'calendar-view-event__completed';
    default:
      return '';
  }
};

const CasesCalendarView = (props) => {
  const {
    onSelectCase,
    cases,
    hospitals = [],
    surgeons = [],
    procedures = [],
  } = props;

  const getSurgeonName = useCallback((surgeonId) => {
    const surgeon = surgeons.find((surgeon) => surgeon.id === surgeonId);
    return surgeon ? `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}` : '';
  }, [surgeons]);

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.name : '';
  }, [hospitals]);

  const getProcedureName = useCallback((procedureId) => {
    const procedure = procedures.find((procedure) => procedure.id === procedureId);
    return procedure ? procedure.name : '';
  }, [procedures]);

  return (
    <div style={{ width: '100%', marginTop: 16 }}>
      <FullCalendar
        plugins={[dayGridPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: ''
        }}
        initialView='dayGridMonth'
        events={cases?.map((item) => ({
          id: item.id,
          surgeonName: getSurgeonName(item.surgeon),
          hospitalName: getHospitalName(item.hospital),
          procedureName: getProcedureName(item.procedure),
          start: item.date,
          allDay: true,
          className: getClassName(item.status),
        }))}
        eventContent={renderEventContent}
        eventClick={(event) => onSelectCase(event?.event?.id)}
      />
    </div>
  );
};

const renderEventContent = (event) => {
  const { surgeonName, hospitalName, procedureName } = event.event.extendedProps;
  return (
    <div style={{ whiteSpace: 'pre-line' }}>
      <b>{surgeonName}</b>
      <br />
      {hospitalName}
      <br />
      {procedureName}
    </div>
  );
};

export default CasesCalendarView;
