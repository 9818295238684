import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ExportIcon from '../../assets/icons/DownloadIcon';
import InventoryIcon from '../../assets/icons/CountIcon';

import ActionButton from '../shared/ActionButton';
import Table from '../shared/table/Table';

import { exportUsageReport } from '../../actions/casesActions';

import { setAllocationStatuses } from '../../constants/enums';
import routes from '../../constants/routes';

const UsageReportingTable = (props) => {
  const {
    usage = [],
    hospitals = [],
    surgeons = [],
    filter,
    filterOptions,
    onFilterChange,
    tenantColor,
    items = [],
  } = props;

  const columns = [
    { title: 'item ref', field: 'ref', type: 'default', sort: true },
    { title: 'lot code', field: 'lot', type: 'default', sort: true },
    { title: 'patient ref', field: 'patientReference', type: 'default', sort: true },
    { title: 'hospital', field: 'hospitalName', type: 'default', sort: true },
    { title: 'surgeon', field: 'surgeonName', type: 'default', sort: true },
    { title: 'case date', field: 'date', type: 'date', sort: true },
    { title: 'submitted', field: 'completedAt', type: 'date', sort: true },
    {
      title: 'usage status',
      field: 'approved',
      type: 'custom',
      sort: false,
      formatter: (value) => (
        !!value && <div style={{ color: setAllocationStatuses.SHIPPED.color }}>Approved</div>
      )
    },
    { title: 'PO №', field: 'orderReference', sort: true, },
    {
      title: 'case id', field: 'caseId', type: 'custom', formatter: (value, row) => (
        <span
          style={{color: tenantColor}}
          onClick={() => onCaseIdClick(value)}
        >
          {row.formattedCaseId}
        </span>
      ),
    },
  ];

  const dispatch = useDispatch();
  const history = useHistory();

  const [exportLoading, setExportLoading] = useState(false);

  const onExportClick = async () => {
    if (!usage || !usage.length) {
      return;
    }

    const data = usage?.map((u) => {
      const refValue = getValue(u.values, 'REF');
      const descValue = getValue(u.values, 'DESC');
      const lotValue = getValue(u.values, 'LOT');

      const item = items?.find((i) => i.code === refValue);
      const description = item && item.description ? item.description : descValue;
      const price = item && item.value ?
        `$${Number(item.value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
        : '';
      const rebateCode = item && item.rebateCode ? item.rebateCode : '';

      const image = usage?.find((im) => im.type === 'IMAGES' && getValue(im.values, 'DESC') && im.caseId === u.caseId);
      const descriptionImage = image ? getValue(image.values, 'DESC') : '';
      const caseId = formatCaseId(u.caseId);

      return {
        ...u,
        hospitalName: getHospitalName(u.hospital),
        surgeonName: getSurgeonName(u.surgeon),
        description,
        ref: refValue,
        lot: lotValue,
        price,
        rebateCode,
        descriptionImage,
        caseId,
      };
    });
    setExportLoading(true);
    await dispatch(exportUsageReport(data));
    setExportLoading(false);
  };

  const getHospitalName = useCallback((hospitalId) => {
    const hospital = hospitals?.find((hospital) => hospital.id === hospitalId);
    return hospital ? hospital.name : '';
  }, [usage, hospitals]);

  const getSurgeonName = useCallback((surgeonId) => {
    const surgeon = surgeons?.find((surgeon) => surgeon.id === surgeonId);
    return surgeon ? `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}` : '';
  }, [usage, surgeons]);

  const getValue = (values = [], key) => {
    if (!values || !values.length || !key) {
      return '';
    }

    const value = values?.find((item) => item.label === key);
    return value?.value || '';
  };

  const onCaseIdClick = (caseId) => {
    history.push(`${routes.CASES}/${caseId}`);
  };

  const formatCaseId = (caseId) => {
    const arr = caseId?.split('-');
    return arr?.length > 1 ? `${arr[0]}-${arr[1]}` : caseId;
  };

  return (
    <div className='cases-table__container'>
      <div className='d-flex m-b-lg space-between align-start'>
        <div className='d-flex'>
          <InventoryIcon />
          <div className='font-size-bg font-bold m-l-md'>
            Item Usage
          </div>
        </div>

        <ActionButton
          onClick={onExportClick}
          bgColor={setAllocationStatuses.SHIPPED.color}
          disabled={exportLoading}
          loading={exportLoading}
        >
          <ExportIcon color="#ffffff" />
          Export
        </ActionButton>
      </div>

      <Table
        rows={usage?.map((item) => ({
          ...item,
          hospitalName: getHospitalName(item.hospital),
          surgeonName: getSurgeonName(item.surgeon),
          ref: getValue(item.values, 'REF'),
          lot: getValue(item.values, 'LOT'),
          formattedCaseId: formatCaseId(item.caseId),
        }))}
        columns={columns}
        pagination
        checkboxes={false}
        withoutCheck
        rowColor='#ffffff'
        rowBorderColor='#fafafa'
        currentFilter={filter}
        filterOptions={filterOptions}
        onFilterChange={onFilterChange}
        additionalData={{
          onClick: onCaseIdClick,
          tenantColor,
        }}
      />
    </div>
  );
};

export default UsageReportingTable;
