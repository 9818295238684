import React from 'react';

import ExpandIcon from '@material-ui/icons/ExpandMore';

import DeleteIcon from '../../../assets/icons/DeleteIcon';
import ItemIcon from '../../../assets/icons/ItemIcon';
import KitIcon from '../../../assets/icons/KitIcon';

import Button from '../../shared/Button';
import DropdownButton from '../../shared/DropdownButton';

import { kitItemTypes } from '../../../constants/enums';

const dropdownOptions = [
  { id: kitItemTypes.KIT.value, label: 'Add Kits', icon: <KitIcon /> },
  { id: kitItemTypes.ITEM.value, label: 'Add Items', icon: <ItemIcon /> },
];

const ParentKitActions = (props) => {
  const {
    onAddClick,
    onDeleteClick,
  } = props;

  return (
    <div className='settings-cmp__filters--main'>
      <div className='settings-cmp__actions'>
        <DropdownButton
          onClick={(option) => onAddClick(option.id)}
          options={dropdownOptions}
        >
          <div className='d-flex m-l-lg'>
            Add
            <ExpandIcon />
          </div>
        </DropdownButton>

        <Button
          type='icon'
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </Button>
      </div>
    </div>
  );
};

export default ParentKitActions;
