import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';

import WarningIcon from '@material-ui/icons/Error';
import ArrowIconRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowIconDown from '@material-ui/icons/KeyboardArrowDown';
import CompletedCountIcon from '../../../assets/icons/CompletedCountIcon';
import OverdueCountIcon from '../../../assets/icons/OverdueCountIcon';
import PendingIcon from '../../../assets/icons/PendingCountIcon';
import CancelIcon from '../../../assets/icons/CancelIcon';
import RequestIcon from '../../../assets/icons/RequestIcon';
import BookedIcon from '../../../assets/icons/BookedIcon';
import OverdueIcon from '../../../assets/icons/OverdueIcon';
import CompletedIcon from '../../../assets/icons/CompletedIcon';
import consignmentIcon from '../../../assets/consignment_icon.svg';
import loanIcon from '../../../assets/loan_icon.svg';
import CheckIcon from '../../../assets/icons/CheckIcon';
import ReceiptIcon from '../../../assets/icons/ReceiptIcon';

import HeaderCell from './HeaderCell';
import TableButton from './TableButton';
import Checkbox from '../Checkbox';
import Progress from '../Progress';

import { userUtils, setsUtils, dateUtils } from '../../../utils';

import { usePrevious } from '../../../hooks';

import { enums, casesConstants } from '../../../constants';
import userRoles, { multiTenantRoles } from '../../../constants/userRoles';
import { countStatuses } from '../../../constants/enums';

const { caseStatusOptions, setAllocationStatuses, kitVariantTypes } = enums;
const { statusColors } = casesConstants;

const StyledTableCell = withStyles(theme => ({
  root: {
    marginTop: '16px',
  },
  head: {
    backgroundColor: 'transparent',
    color: '#b1b1b1',
    borderTop: '1px solid #f3f3f4',
    borderBottom: '1px solid #f3f3f4',
    paddingTop: '16px',
    paddingBottom: '06px',
    textTransform: 'uppercase',
    // fontSize: '0.8rem',
  },
  body: {
    color: '#0b0b0b',
    borderTop: '1px solid #EEEEEE',
    borderBottom: '1px solid #EEEEEE',
    backgroundColor: props => props.background,
    paddingTop: '16px',
    paddingBottom: '16px',
    // fontSize: '0.8rem',
    fontWeight: 'bold',
    '&:first-child': {
      borderRadius: props => props.checkboxes ? '0' : '24px 0 0 24px'
    },
    '&:last-child': {
      borderRadius: '0 24px 24px 0'
    }
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    fontWeight: 'bold',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderBottom: props => `10px solid ${props.color}`,
    borderTop: props => `10px solid ${props.color}`,
    '&:last-child': {}
  },
}))(TableRow);

const StyledChildTableRow = withStyles(theme => ({
  root: {
    fontWeight: 'bold',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderBottom: props => `2px solid ${props.color}`,
    borderTop: props => `2px solid ${props.color}`,
    '&:last-child': {}
  },
}))(TableRow);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    boxShadow: 'none'
  },
  table: {
    minWidth: 300,
    marginTop: '16px'
  },
  checkboxActive: {
    paddingLeft: '16px',
    paddingTop: '12px',
    paddingBottom: '12px',
    backgroundColor: '#ffffff',
    borderRadius: '24px 0 0 24px'
  },
  checkbox: {
    paddingLeft: '16px',
    paddingTop: '12px',
    paddingBottom: '12px',
    backgroundColor: '#FAFAFA',
    borderRadius: '24px 0 0 24px'
  },
  expandableToggle: {
    paddingLeft: '12px',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingRight: '8px',
    backgroundColor: '#FFFFFF',
    borderRadius: '24px 0 0 24px'
  },
  checkboxHead: {
    paddingLeft: '16px',
    paddingTop: '4px',
    paddingBottom: '0px',
  }
}));

const renderCell = (column, row, additionalData = {}) => {
  const type = column.type;
  const value = row[column.field];
  const { tenantColor } = additionalData;

  switch (type) {
    case 'status':
      return !!value ? 'Active' : 'Deactivated';
    case 'role':
      return userRoles[value] ? userRoles[value].label : '';
    case 'date':
      return value ? dateUtils.formatDate(value) : '';
    case 'caseStatus':
      return <div className='d-flex'>
        {!!value.status && (
          <div className='m-r-md' style={{ marginTop: 4 }}>{getStatusIcon(value.status, value.canceled, value.proformaSent, value.orderClosed)}</div>
        )}
        {!!value.kitVariant && value.status !== caseStatusOptions.request && (
          value.kitVariant === kitVariantTypes.consignment && !!value.additional ? (
            <div className="d-flex">
              <div className="case-variant-icon">{getVariantIcon(kitVariantTypes.consignment)}</div>
              <div className="m-l-md case-variant-icon">{getVariantIcon(kitVariantTypes.loan)}</div>
            </div>
          ) : (
            <div className="case-variant-icon">{getVariantIcon(value.kitVariant)}</div>
          )
        )}
      </div>;
    case 'userStatus':
      const { active, role, connected } = row;
      const { surgeons = [] } = additionalData;

      if (!active) {
        return 'Deactivated';
      }

      if ([...multiTenantRoles].includes(role) || connected) {
        if (userUtils.isAssigned(row, surgeons)) {
          return 'Assigned';
        } else {
          return (<div className='d-flex' style={{ color: statusColors.REQUEST }}>
            <span className='m-r-lg'>Review</span>
            <WarningIcon style={{ color: statusColors.REQUEST }} />
          </div>);
        }
      }

      return 'Active';
    case 'setPosition':
      return setsUtils.getPositionLabel(value);
    case 'setAllocationStatus':
      return setsUtils.getSetAllocationStatus(value);
    case 'setsAllocation':
      return row.status === setAllocationStatuses.AVAILABLE.value && (!row.sets || !row.sets.length) ? (
        <span style={{ color: setAllocationStatuses.RETURNED.color }}>Assign Set</span>
      ) : (
        <div>{value.map((set) => <div key={set}>{set}</div>)}</div>
      );
    case 'caseId':
      const { onClick } = additionalData;
      return (
        <span
          style={{ color: tenantColor }}
          onClick={() => onClick(value)}
        >
          {value}
        </span>
      );
    case 'scans':
      const tags = additionalData.tags || [];
      const tag = row.tag ? tags?.find((t) => t.id === row.tag) : null;
      return value && Array.isArray(value) ? (
        <div>
          {tag && (
            <div style={{ color: tag.color || '#000000', marginBottom: '6px' }}>
              {tag.name}
            </div>
          )}
          <div className='d-flex'>
            {['GTIN', 'REF', 'LOT', 'EXP', 'DESC'].map((key) => {
              const scan = value?.find((v) => v.label === key);
              if (scan && scan.value) {
                return (
                  <div key={`${scan.label}_${scan.value}`} className='m-r-lg'>
                    <div className='font-bold secondary m-b-sm'>{scan.label}</div>
                    <div className='font-bold'>{scan.value}</div>
                  </div>
                )
              }
              return null;
            })}
          </div>
        </div>
      ) : null;
    case 'colored':
      return <div style={{ color: value.color }}>{value.value}</div>;
    case 'progress':
      return <Progress value={value.value} variant='determinate' bgcolor={value.color} />;
    case 'warning':
      return !!value && <WarningIcon style={{ color: statusColors.REQUEST }} />;
    case 'error':
      return !!value && <WarningIcon style={{ color: statusColors.OVERDUE }} />;
    case 'list':
      return Array.isArray(value) && (
        <div className='d-flex direction-column align-start'>
          {value.map((item) => (
            <div key={Math.random() * Math.random()} className='m-b-sm'>
              {item}
            </div>
          ))}
        </div>
      );
    case 'action':
      if (value && value.onClick) {
        return (
          <TableButton
            onClick={value.onClick}
            // color={tenantColor}
          >
            {value.icon}
          </TableButton>
        );
      } else {
        return null;
      }
    case 'action-primary':
      if (value && value.onClick) {
        return (
          <TableButton
            onClick={value.onClick}
            color={tenantColor}
          >
            {value.icon}
          </TableButton>
        );
      } else {
        return null;
      }
    case 'countStatus':
      return (
        <div className='d-flex'>
          {getCountStatusIcon(value)}
          {row.kit && (
            <div className='case-variant-icon'>
              <img
                className='m-l-md'
                src={consignmentIcon}
                alt='consignment_icon'
              />
            </div>
          )}
        </div>
      );
    case 'custom':
      return column.formatter ? column.formatter(value, row) : value;
    default:
      return value;
  }
};

const getStatusIcon = (status, canceled, proformaSent, orderClosed) => {
  if (canceled) {
    return <CancelIcon fontSize='small' color={statusColors.OVERDUE} />;
  }

  switch (status) {
    case caseStatusOptions.request:
      return <RequestIcon fontSize='small' color={statusColors.REQUEST} />;
    case caseStatusOptions.booked:
      return <BookedIcon fontSize='small' color={statusColors.BOOKED} />;
    case caseStatusOptions.overdue:
      return <OverdueIcon fontSize='small' color={statusColors.OVERDUE} />;
    case caseStatusOptions.completed:
      if (proformaSent) {
        if (orderClosed) {
          return <CheckIcon fontSize='small' color={statusColors.COMPLETED} />
        } else {
          return <ReceiptIcon fontSize='small' color={statusColors.COMPLETED}  />
        }
      }
      return <CompletedIcon fontSize='small' color={statusColors.COMPLETED} />;
    default:
      return <RequestIcon fontSize='small' color={statusColors.REQUEST} />;
  }
};

const getCountStatusIcon = (status) => {
  switch (status) {
    case countStatuses.PENDING.value:
      return <PendingIcon color={countStatuses.PENDING.color} />;
    case countStatuses.COMPLETED.value:
      return <CompletedCountIcon color={countStatuses.COMPLETED.color} />;
    case countStatuses.OVERDUE.value:
      return <OverdueCountIcon color={countStatuses.OVERDUE.color} />;
    default:
      return null;
  }
};

const getVariantIcon = (variant) => {
  switch (variant) {
    case kitVariantTypes.consignment:
      return (
        <img
          src={consignmentIcon}
          alt='consignment_icon'
        />
      );
    case kitVariantTypes.loan:
      return (
        <img
          src={loanIcon}
          alt='loan_icon'
        />
      );
    default:
      return null;
  }
};

const TableComponent = (props) => {
  const {
    columns,
    rows,
    onRowClick,
    selected,
    handleCheck,
    handleCheckAll,
    checkedRows,
    additionalData,
    rowColor = '#fafafa',
    rowBorderColor = '#f3f3f4',
    pagination,
    checkboxes = true,
    noCheckAll = false,
    className,
    onFilterChange = () => {},
    currentFilter = {},
    filterOptions = {},
  } = props;
  const classes = useStyles();

  const tenantColor = useSelector((state) => state.tenant.currentTenant.colorPrimary);

  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (rowId) => {
    setExpandedRows((prev) =>
      prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
    );
  };

  const isRowExpanded = (rowId) => expandedRows.includes(rowId);

  const prevRows = usePrevious(rows);

  useEffect(() => {
    if (rows && prevRows && rows.length !== prevRows.length) {
      setPage(0);
    }
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    const valueA = orderBy.includes('date') ? moment(a[orderBy]).format() : a[orderBy];
    const valueB = orderBy.includes('date') ? moment(b[orderBy]).format() : b[orderBy];

    if (valueA > valueB) {
      return -1;
    }
    if (valueA < valueB) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);

      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  const list = useMemo(() => {
    if (rows && rows.length) {
      const sortedRows = stableSort(rows, getComparator(order, orderBy)) || [];
      return pagination ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : sortedRows;
    }
    return [];
  },[rows, order, orderBy, page, rowsPerPage]);

  return (
    <Table className={`${classes.table}${className ? ` ${className}` : ''}`} aria-label="customized table">
      <TableHead>
        <TableRow>
          {checkboxes && (
            <th className={classes.checkboxHead} style={{ width: '45px' }}>
              {!noCheckAll && (
                <Checkbox
                  input={{
                    checked: !!rows.length && rows.every((row) => checkedRows.includes(row.id)),
                    onClick: handleCheckAll,
                  }}
                />
              )}
            </th>
          )}
          {columns.map((column) => (
            <HeaderCell
              key={`header-cell-${column.title}-${Math.random()}`}
              field={column.field}
              title={column.title}
              sort={column.sort}
              order={order}
              orderBy={orderBy}
              onSort={handleSort}
              filterOptions={column.filter && filterOptions[column.field] ? filterOptions[column.field] : null}
              selectedValues={column.filter && currentFilter[column.field] ? currentFilter[column.field] : []}
              onFilterChange={(values) => onFilterChange(column.field, values)}
              filterTitle={column.filterTitle}
            />
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows && rows.length ? (
          list.map((row) => (
            <React.Fragment key={row.id}>
              <StyledTableRow
                color={rowBorderColor}
                onClick={() => onRowClick(row)}
                className={row.isExpandable ? 'expandable-row' : ''}
                style={{ borderBottom: row.isExpandable && isRowExpanded(row.id) ? '2px' : '' }}
              >
                {checkboxes && !row.isExpandable && (
                  <td
                    className={
                      row.id === selected || rowColor === '#ffffff'
                        ? classes.checkboxActive
                        : classes.checkbox
                    }
                  >
                    <Checkbox
                      input={{
                        onClick: (e) => {
                          e.stopPropagation();
                          handleCheck(row.id);
                        },
                        checked: checkedRows?.indexOf(row.id) !== -1,
                      }}
                    />
                  </td>
                )}
                {row.isExpandable && (
                  <td
                    className={classes.expandableToggle}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleRowExpansion(row.id);
                    }}
                  >
                    <IconButton
                      onClick={() => {}}
                      size='small'
                    >
                      {isRowExpanded(row.id) ? <ArrowIconDown /> : <ArrowIconRight />}
                    </IconButton>
                  </td>
                )}
                {columns.map((column) => (
                  <StyledTableCell
                    key={`cell-${column.title}_${row.id}_${Math.random()}`}
                    component="td"
                    scope="row"
                    background={row.id === selected ? '#ffffff' : rowColor}
                    checkboxes={checkboxes}
                  >
                    {renderCell(column, row, { ...additionalData, tenantColor })}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
              {isRowExpanded(row.id) &&
                row.children &&
                row.children.map((childRow) => (
                  <StyledChildTableRow
                    key={`child-${childRow.id}`}
                    color={rowBorderColor}
                    className="child-row"
                    onClick={() => onRowClick(childRow)}
                  >
                    <td className={classes.checkbox} style={{ backgroundColor: '#ffffff' }}>
                      <Checkbox
                        input={{
                          onClick: () => handleCheck(childRow.id),
                          checked: checkedRows?.indexOf(childRow.id) !== -1,
                        }}
                      />
                    </td>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={`child-cell-${column.title}_${childRow.id}_${Math.random()}`}
                        component="td"
                        scope="row"
                        background={rowColor}
                      >
                        {renderCell(column, childRow, { ...additionalData, tenantColor })}
                      </StyledTableCell>
                    ))}
                  </StyledChildTableRow>
                ))}
            </React.Fragment>
          ))
        ) : (
          <tr className="p-lg">
            <td className="secondary text-center p-lg font-size-bg" colSpan={columns.length + 1}>
              EMPTY LIST
            </td>
          </tr>
        )}
        {pagination && (
          <tr>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </tr>
        )}
      </TableBody>
    </Table>
  );
};

TableComponent.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  currentFilter: PropTypes.object,
  filterOptions: PropTypes.object,
  onRowClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  stickyTable: PropTypes.bool,
  surgeons: PropTypes.array,
  checkboxes: PropTypes.bool,
};

TableComponent.defaultProps = {
  onRowClick: () => {},
  handleCheck: () => {},
  handleCheckAll: () => {},
  checkedRows: [],
  checkboxes: true,
};

export default memo(TableComponent);
