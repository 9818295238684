import React, { useState, useMemo, useCallback } from 'react';
import moment from 'moment';

import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import CalendarIcon from '../../assets/icons/GoogleCalendarIcon';
import ProcedureIcon from '../../assets/icons/ProcedureIcon';
import ProductIcon from '../../assets/icons/ProductIcon';
import KitsIcon from '../../assets/icons/KitIcon';
import FolderIcon from '../../assets/icons/FolderIcon';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import DeleteForever from '../../assets/icons/DeleteIcon';
import ShippingIcon from '../../assets/icons/ShippingIcon';
import PreferenceIcon from '../../assets/icons/PreferenceIcon';
import CancelIcon from '../../assets/icons/CancelIcon';
import BookedIcon from '../../assets/icons/BookedIcon';

import CaseGuidanceModal from './modals/CaseGuidanceModal';
import CasePreferencesModal from './modals/CasePreferencesModal';
import Button from '../shared/Button';
import { ConfirmationModal } from '../shared/modal';
import Select from '../shared/Select';

import { getCommaSeparatedNames } from '../../utils/table';
import { getStatusIcon, getStatusLabel } from '../../utils/cases';

import { statusColors } from '../../constants/cases';
import { kitVariantTypes, caseStatusOptions } from '../../constants/enums';
import roles from '../../constants/userRoles';
import CancelCaseModal from './modals/CancelCaseModal';

const ITEM_HEIGHT = 48;

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: '12px',
    paddingBottom: '12px',
  }
}))(MenuItem);

const CaseInfo = (props) => {
  const {
    activeCase,
    hospitals,
    procedures,
    procedureOptions,
    surgeons,
    products: productsList,
    kits: kitsList,
    onDelete,
    onCancelCase,
    fetching,
    isDeleting,
    caseGuidance,
    casePreferences,
    onCaseGuidanceModalOpen,
    onCasePreferencesModalOpen,
    view,
    setView,
    viewOptions,
    minimized = false,
    onEditClick,
    caseWriteDisabled = false,
    tenantColor,
    onAddKitsClick,
    users = [],
    userRole,
    onAddToCalendar,
    additional,
  } = props;
  const {
    active = true,
    date,
    time = '',
    status,
    hospital,
    procedure,
    procedureOption,
    surgeon,
    products,
    kits,
    patientReference,
    kitVariant,
    deliveryAddress,
    noEquipmentToShip,
    createdAt,
    createdBy,
  } = activeCase;
  const {
    label = '',
    street = '',
    city = '',
    state = '',
  } = deliveryAddress || {};

  const [deleteModalOpen, toggleDeleteModal] = useState(false);
  const [cancelModalOpen, toggleCancelModal] = useState(false);
  const [caseGuidanceModalOpen, toggleCaseGuidanceModal] = useState(false);
  const [casePreferencesModalOpen, toggleCasePreferencesModal] = useState(false);

  const hospitalName = useMemo(() => {
    const caseHospital = hospitals.find((item) => item.id === hospital);
    return caseHospital ? caseHospital.name : '';
  }, [hospital, hospitals]);
  const procedureName = useMemo(() => {
    const caseProcedure = procedures.find((item) => item.id === procedure);
    return caseProcedure ? caseProcedure.name : '';
  }, [procedure, procedures]);
  const procedureOptionName = useMemo(() => {
    const option = procedureOptions.find((item) => item.id === procedureOption);
    return option ? option.name : '';
  }, [procedureOption, procedureOptions]);
  const surgeonName = useMemo(() => {
    const caseSurgeon = surgeons.find((item) => item.id === surgeon);
    return caseSurgeon ? `${caseSurgeon.title} ${caseSurgeon.firstName} ${caseSurgeon.lastName}` : '';
  }, [activeCase, surgeons]);
  const productNames = useMemo(() => getCommaSeparatedNames(productsList, products), [products, productsList]);
  const kitNames = useMemo(() => getCommaSeparatedNames(kitsList, kits), [kits, kitsList]);

  const options = useMemo(() => Object.values(viewOptions),[viewOptions]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserName = useCallback((uid) => {
    const user = users.find((u) => u.uid === uid);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }, [users]);

  return (
    <div className='case-info__container'>
      <div className='case-actions'>
        <div style={{ width: '294px' }}>
          <Button
            type='submit'
            text='Edit Info'
            onClick={onEditClick}
            disabled={caseWriteDisabled}
          />
          {activeCase?.status !== caseStatusOptions.completed && (
            <Button
              type='icon'
              aria-label='more'
              aria-controls='long-menu'
              aria-haspopup='true'
              onClick={handleClick}
              disabled={caseWriteDisabled}
            >
              <DeleteIcon color={caseWriteDisabled ? '#9e9e9e' : '#000000'} />
            </Button>
          )}

          <Menu
            id='long-menu'
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
              elevation: 3,
            }}
          >
            <StyledMenuItem onClick={() => toggleCancelModal(true)}>
              <CancelIcon />
              <span className='m-l-md'>Cancel</span>
            </StyledMenuItem>
            <StyledMenuItem
              disabled={userRole !== roles.ADMIN.name}
              onClick={() => toggleDeleteModal(true)}
            >
              <DeleteForever />
              <span className='m-l-md'>Delete</span>
            </StyledMenuItem>
          </Menu>
        </div>
        <div style={{ width: '200px' }}>
          <Select
            value={view}
            onChange={setView}
            options={options}
          />
        </div>
      </div>

      <div className='case-info'>
        <div className='case-info__main'>
          <div>
            <div className='d-flex'>
              {getStatusIcon(status, true, !active)}
              <div className='font-size-bg font-bold m-l-md m-r-sm'>
                {date ? moment(date).local().format('Do MMM YYYY') : ''}
              </div>
              <div className='font-bold secondary m-t-sm'>{time}</div>
              <div className='m-l-md'>
                <Tooltip
                  title='Add to Google Calendar'
                  aria-label='calendar'
                >
                  <IconButton
                    aria-controls='calendar'
                    aria-haspopup='true'
                    color='primary'
                    onClick={onAddToCalendar}
                    size='small'
                  >
                    <CalendarIcon color={tenantColor} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className='m-t-md'>
              <div className='font-size-bg font-bold'>{surgeonName}</div>
              <div>{hospitalName}</div>
              <div>{patientReference}</div>
            </div>
            {!!kitVariant && status !== caseStatusOptions.request && (
              <div className='m-t-lg d-flex'>
                <div
                  className='case-kit-variant'
                  style={{
                    color: kitVariant === kitVariantTypes.consignment ? statusColors.REQUEST : statusColors.BOOKED,
                    borderColor: kitVariant === kitVariantTypes.consignment ? statusColors.REQUEST : statusColors.BOOKED,
                  }}
                >
                  {kitVariant === kitVariantTypes.consignment ? 'Consignment' : 'Loan'}
                </div>
                {additional && (
                  <div
                    className='m-l-md case-kit-variant'
                    style={{
                      color: statusColors.BOOKED,
                      borderColor: statusColors.BOOKED,
                    }}
                  >
                    Loan
                  </div>
                )}
              </div>
            )}
          </div>
          <div>
            <div className='case-status' style={{ background: active ? statusColors[status] : statusColors.OVERDUE }}>
              <div className='d-flex direction-column space-between'>
                {getStatusIcon(status, false, !active)}
                <div className='case-status-label'>
                  {getStatusLabel(status, !active)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {!minimized && (
          <>
            <div className='divider' />

            <div className='d-flex direction-column space space-between align-start flex-1'>
              <div>
                <div className='d-flex align-start m-t-sm'>
                  <ProcedureIcon />
                  <span className='m-l-md'>{`${procedureName}${procedureOptionName ? `, ${procedureOptionName}` : ''}`}</span>
                </div>
                <div className='d-flex align-start m-t-sm'>
                  <ProductIcon />
                  <span className='m-l-md'>{productNames}</span>
                </div>

                <div className='d-flex align-start m-t-sm'>
                  <KitsIcon />
                  {noEquipmentToShip ? (
                    <span className='m-l-md'>No equipment to ship</span>
                  ) : (
                    kits && kits.length ? (
                      <span className='m-l-md'>{kitNames}</span>
                    ) : (
                      <span
                        className='m-l-md pointer font-bold'
                        style={{ color: tenantColor }}
                        onClick={onAddKitsClick}
                      >
                        Add Kits
                      </span>
                    )
                  )}
                </div>

                {deliveryAddress && (
                  <div className='d-flex align-start m-t-sm'>
                    <ShippingIcon />
                    <div className='m-l-md'>
                      <span className='font-bold'>{label}</span>
                      <span className='m-l-sm'>({[street, city, state].filter((v) => !!v).join(', ')})</span>
                    </div>
                  </div>
                )}

                {status !== caseStatusOptions.request && !!createdAt && !!createdBy && (
                  <div className='d-flex align-start m-t-sm'>
                    <BookedIcon />
                    <div className='m-l-md'>
                      <span className='font-bold'>{getUserName(createdBy)}</span>
                      <span className='m-l-sm'>booked on {moment(createdAt).format('DD/MM/YYYY')}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='case-buttons'>
                <div
                  className='case-button'
                  onClick={() => {
                    onCaseGuidanceModalOpen();
                    toggleCaseGuidanceModal(true);
                  }}
                >
                  <FolderIcon />
                  <div className='font-bold m-t-md'>Case Guidance</div>
                  <div className='font-size-sm secondary'>Click to view</div>
                </div>
                <div
                  className='case-button'
                  onClick={() => {
                    onCasePreferencesModalOpen();
                    toggleCasePreferencesModal(true);
                  }}
                >
                  <PreferenceIcon />
                  <div className='font-bold m-t-md'>Surgeon Preference</div>
                  <div className='font-size-sm secondary'>Click to view</div>
                </div>
              </div>
            </div>
          </>
        )}

        {minimized && view === 'SETS' && deliveryAddress && (
          <div className='m-t-lg'>
            <div className='d-flex align-start'>
              <ProcedureIcon />
              <span className='m-l-md'>{`${procedureName}${procedureOptionName ? `, ${procedureOptionName}` : ''}`}</span>
            </div>
            <div className='d-flex align-start m-t-sm'>
              <ShippingIcon />
              <div className='m-l-md'>
                <span className='font-bold'>{label}</span>
                <span className='m-l-sm'>({[street, city, state].filter((v) => !!v).join(', ')})</span>
              </div>
            </div>
          </div>
        )}
      </div>

      <ConfirmationModal
        open={deleteModalOpen}
        onClose={() => toggleDeleteModal(false)}
        onSubmit={onDelete}
        title='Are you sure you want to delete this case?'
        submitText='Delete'
        loading={isDeleting}
      />

      <CancelCaseModal
        open={cancelModalOpen}
        onClose={() => toggleCancelModal(false)}
        onSubmit={onCancelCase}
        loading={isDeleting}
      />

      <CaseGuidanceModal
        open={caseGuidanceModalOpen}
        onClose={() => toggleCaseGuidanceModal(false)}
        files={caseGuidance}
        fetching={fetching}
      />
      <CasePreferencesModal
        open={casePreferencesModalOpen}
        onClose={() => toggleCasePreferencesModal(false)}
        preferences={casePreferences}
        fetching={fetching}
      />
    </div>
  );
};

export default CaseInfo;
