import React, { useMemo } from 'react';

import Modal from '../shared/modal';
import Input from '../shared/Input';
import Autocomplete from '../shared/autocomplete/Autocomplete';

import './kits.scss';

const KitFiltersModal = (props) => {
  const {
    open,
    onClose,
    activeTab = 0,
    hideShipped,
    hideConsignment,
    hideLoan,
    toggleShipped,
    toggleConsignment,
    toggleLoan,
    surgeon,
    hospital,
    state,
    onSurgeonChange,
    onHospitalChange,
    onStateChange,
    surgeons = [],
    hospitals = [],
    states = [],
    title,
    toggleCanceled,
    showCanceled,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const surgeonOptions = useMemo(() => {
    return [
      { label: 'All', value: '' },
      ...surgeons?.map((surgeon) => ({
        value: surgeon.id,
        label: `${surgeon.title} ${surgeon.firstName} ${surgeon.lastName}`
      }))
    ];
  }, [surgeons]);

  const hospitalOptions = useMemo(() => {
    return [
      { label: 'All', value: '' },
      ...hospitals?.map((hospital) => ({ value: hospital.id, label: hospital.name }))
    ];
  }, [hospitals]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={title || 'Filter Kits'}
      fullWidth
    >
      <div className='kits-filters-modal__container'>
        {activeTab === 1 && (
          <>
            <Input
              type='switch'
              value={hideShipped}
              onChange={toggleShipped}
              placeholder='Hide All Shipped'
            />
          </>
        )}

        {/*{activeTab === 1 && (*/}
        {/*  <>*/}
        {/*    <Input*/}
        {/*      type='switch'*/}
        {/*      value={hideReturned}*/}
        {/*      onChange={toggleReturned}*/}
        {/*      placeholder='Hide All Returned'*/}
        {/*    />*/}
        {/*  </>*/}
        {/*)}*/}

        <Input
          type='switch'
          value={hideConsignment}
          onChange={toggleConsignment}
          placeholder='Hide Consignment Cases'
        />

        <Input
          type='switch'
          value={hideLoan}
          onChange={toggleLoan}
          placeholder='Hide Loan Cases'
        />

        <Input
          type='switch'
          value={showCanceled}
          onChange={toggleCanceled}
          placeholder='Show Canceled'
        />

        <div>
          <Autocomplete
            value={surgeon}
            onChange={onSurgeonChange}
            options={surgeonOptions}
            placeholder="Filter by Surgeon"
          />
        </div>

        <div className='m-t-lg'>
          <Autocomplete
            value={hospital}
            onChange={onHospitalChange}
            options={hospitalOptions}
            placeholder="Filter by Hospital"
          />
        </div>

        <div className='m-t-lg'>
          <Autocomplete
            value={state}
            onChange={onStateChange}
            options={states}
            placeholder="Filter by State"
          />
        </div>
      </div>
    </Modal>
  );
};

export default KitFiltersModal;
