import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '@material-ui/icons/KeyboardArrowLeft';

import { billOfMaterialItemTypes } from '../../../constants/enums';
import FullScreenIcon from '@material-ui/icons/Fullscreen';

const BOMSectionsView = ({ sections, kitName, onBackClick, items = [] }) => {
  return (
    <div>
      <div className="d-flex">
        <IconButton
          color="primary"
          className="m-r-md"
          onClick={onBackClick}
          size="small"
        >
          <ArrowIcon fontSize="large" color="primary" />
        </IconButton>
        <div className="m-l-sm font-bold font-size-bg">
          {kitName}
        </div>
      </div>

      {sections?.map((section) => (
        <div key={section.id}>
          <div className="image-container">
            {section.image && (
              <>
                <img src={section?.image?.downloadUrl} alt='section-preview' />
                <div
                  className='full-screen-icon'
                  onClick={() => window.open(section?.image?.downloadUrl, '_blank')}
                >
                  <FullScreenIcon color='secondary' fontSize='large' />
                </div>
              </>
            )}
          </div>

          <table className="bom-items-table">
            <thead>
            <tr>
              <th>Item Code</th>
              <th>Description</th>
              <th>Type</th>
              <th>Ref</th>
              <th>QTY</th>
            </tr>
            </thead>
            <tbody>
            {section?.items?.filter((item) => item.type !== billOfMaterialItemTypes.NOTES.value).map((item) => {
              const _item = items?.find((i) => i.id === item.id);
              return (
                <tr key={item.id}>
                  <td>{_item.code}</td>
                  <td>{_item.checklistDescription || _item.description}</td>
                  <td>{item.type}</td>
                  <td>{item.ref}</td>
                  <td>{item.quantity}</td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default BOMSectionsView;
